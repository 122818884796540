import React, { useState, useEffect, useCallback } from "react";
import PageHeader from "components/page-header";
import QRCode from "qrcode.react";
import "./styles.css";
import { cpfMask, phoneMask } from "../../../mask.js";
import Api from "../../../services/api";
import axios from "axios";
import { ToastContainer } from "react-toastr";
import Swal from "sweetalert2";
let container;

const UserEdit = () => {
  const [data, setData] = useState({
    id: "",
    document: "",
    phone: "",
    user_id: "",
    address_id: "",
    address: {
      id: "",
      zip_code: "",
      street: "",
      neighborhood: "",
      complement: "",
      state: "",
      city: "",
      number: "",
    },
    user: {
      id: "",
      email: "",
      first_name: "",
      last_name: "",
      qrcode: "",
    },
  });

  const urlParams = new URLSearchParams(window.location.search);
  const user_id = urlParams.get("user_id");
  const fetchData = useCallback(async () => {
    const result = await Api.get(`show/${user_id}`);
    if (result.data.profile) {
      let address = null;
      if (result.data.profile.address != null) {
        address = {
          id: result.data.profile.address.id,
          zip_code: result.data.profile.address.zip_code
            ? result.data.profile.address.zip_code
            : "",
          street: result.data.profile.address.street
            ? result.data.profile.address.street
            : "",
          neighborhood: result.data.profile.address.neighborhood
            ? result.data.profile.address.neighborhood
            : "",
          complement: result.data.profile.address.complement
            ? result.data.profile.address.complement
            : "",
          state: result.data.profile.address.state
            ? result.data.profile.address.state
            : "",
          city: result.data.profile.address.city
            ? result.data.profile.address.city
            : "",
          number: result.data.profile.address.number
            ? result.data.profile.address.number
            : "",
        };
      }
      setData({
        id: result.data.profile.id,
        document: result.data.profile.document
          ? result.data.profile.document
          : "",
        phone: result.data.profile.phone ? result.data.profile.phone : "",
        address,
        user: {
          id: result.data.user.id,
          email: result.data.user.email ? result.data.user.email : "",
          first_name: result.data.user.first_name
            ? result.data.user.first_name
            : "",
          last_name: result.data.user.last_name
            ? result.data.user.last_name
            : "",
          qrcode: result.data.user.qrcode ? result.data.user.qrcode : "",
        },
      });
    } else {
      let idprofile = result.data.profile ? result.data.profile.id : null;
      setData({
        id: idprofile,
        user: {
          id: result.data.user.id,
          email: result.data.user.email,
          first_name: result.data.user.first_name,
          last_name: result.data.user.last_name,
          qrcode: result.data.user.qrcode,
        },
      });
    }
  }, [user_id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const getCep = () => {
    axios
      .get(`https://viacep.com.br/ws/${data.address.zip_code}/json`)
      .then((res) => {
        setData({
          ...data,
          address: {
            ...data.address,
            street: res.data.logradouro,
            state: res.data.uf,
            city: res.data.localidade,
            neighborhood: res.data.bairro,
            complement: res.data.complemento,
          },
        });

        var x = document.getElementById("invalid-cep");
        if (!x.classList.contains("hide")) {
          x.classList.add("hide");
          x.classList.add("invalid-cep");
          x.classList.remove("invalid-cep-show");
        }
      })
      .catch((err) => {
        setData({
          ...data,
          address: {
            ...data.address,
            street: "",
            state: "",
            city: "",
            neighborhood: "",
          },
        });
        var x = document.getElementById("invalid-cep");
        if (x.classList.contains("hide")) {
          x.classList.remove("hide");
          x.classList.add("invalid-cep-show");
          x.classList.remove("invalid-cep");
        }
      });
  };

  const gerarQRCODE = (event) => {
    Api.get(`/gera/qrcode/user/${user_id}`)
      .then((res) => {
        fetchData();
      })
      .catch(() => {
        Swal.fire({
          title: "Ops!",
          icon: "error",
          text: "Ocorreu um erro interno, por favor tente novamente",
          confirmButtonText: "Ok",
        });
      });
  };

  // const [pass, setPass] = useState({
  //   password: "",
  //   confirmpassword: "",
  // });

  const updateUser = (e) => {
    setData({
      ...data,
      user: {
        ...data.user,
        [e.target.name]: e.target.value,
      },
    });
  };

  const updateCpf = (e) => {
    setData({
      ...data,
      [e.target.name]: cpfMask(e.target.value),
    });
  };

  const updatePhone = (e) => {
    setData({
      ...data,
      [e.target.name]: phoneMask(e.target.value),
    });
  };

  const updateAddress = (e) => {
    setData({
      ...data,
      address: {
        ...data.address,
        [e.target.name]: e.target.value,
      },
    });
  };

  const updateCep = (e) => {
    setData({
      ...data,
      address: {
        ...data.address,
        [e.target.name]: e.target.value,
      },
    });
  };

  // const updatePass = (e) => {
  //   setPass({
  //     ...pass,
  //     [e.target.name]: e.target.value,
  //   });
  // };

  const editUser = (e) => {
    e.preventDefault();
    Api.post("useredit", data)
      .then((res) => {
        container.success("Usuario Atualizado");
        window.setTimeout(function () {
          window.location.href = "/users";
        }, 3000);
      })
      .catch((err) => {
        container.error("Erro ao atualizar usuário");
      });
  };

  return (
    <>
      <PageHeader title="Usuários" text="Editar Usuário"></PageHeader>

      <div className="container-fluid mt-4">
        <ToastContainer
          ref={(ref) => (container = ref)}
          className="toast-top-right"
        />

        <div className="col-12">
          <div className="card">
            <div className="card-header container-register-user">
              <h4 className="card-header-title">1. Campos obrigatórios</h4>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-8">
                  <div className="row">
                    <div className="col-md-12">
                      <label htmlFor="email">E-mail</label>
                      <input
                        type="email"
                        placeholder="exemplo@seuemail.com.br"
                        className="form-control"
                        name="email"
                        value={data.user.email}
                        onChange={updateUser}
                      />
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-6">
                      <label htmlFor="user.first_name">Nome</label>
                      <input
                        type="text"
                        placeholder="Nome"
                        className="form-control"
                        name="first_name"
                        value={data.user.first_name}
                        onChange={updateUser}
                      />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="last_name">Sobrenome</label>
                      <input
                        type="text"
                        placeholder="Sobrenome"
                        className="form-control"
                        name="last_name"
                        value={data.user.last_name}
                        onChange={updateUser}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="row">
                    <div className="col-md-12">
                      {data.user.qrcode ? (
                        <div style={{ textAlign: "center" }}>
                          <label>QRcode</label>
                          <br />
                          <QRCode value={data.user.qrcode} />
                        </div>
                      ) : (
                        <>
                          <label>QRcode</label>
                          <button
                            className="btn btn-primary c3"
                            onClick={gerarQRCODE}
                          >
                            Gerar QRcode
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="card">
            <div className="card-header container-register-user">
              <h4 className="card-header-title">2. Dados Opcionais</h4>
            </div>

            <div className="card-body">
              <div id="dados-pessoais" className="dados-pessoais">
                <div className="row">
                  <div className="col-md-6">
                    <label htmlFor="cpf">CPF ou CNPJ</label>
                    <input
                      type="text"
                      placeholder="000.000.000-00"
                      className="form-control"
                      name="document"
                      value={data.document}
                      onChange={updateCpf}
                    />
                  </div>
                  <div className="col-md-3">
                    <label htmlFor="phone">Telefone</label>
                    <input
                      type="text"
                      placeholder="(DDD)"
                      className="form-control"
                      name="phone"
                      value={data.phone}
                      onChange={updatePhone}
                    />
                  </div>
                  <div className="col-md-3">
                    <label htmlFor="zip_code">CEP</label>
                    <input
                      type="text"
                      placeholder="Cep"
                      className="form-control"
                      name="zip_code"
                      value={data.address?.zip_code}
                      onChange={updateCep}
                      onBlur={getCep}
                    />
                    <div className="invalid-cep hide" id="invalid-cep">
                      <span style={{ color: "#e63757" }}>Cep Inválido.</span>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <label htmlFor="street">Rua</label>
                    <input
                      type="text"
                      placeholder="Rua"
                      className="form-control"
                      name="street"
                      value={data.address?.street}
                      onChange={updateAddress}
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="complement">Complemento</label>
                    <input
                      type="text"
                      placeholder="Complemento"
                      className="form-control"
                      name="complement"
                      value={data.address?.complement}
                      onChange={updateAddress}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-8">
                    <label htmlFor="neighborhood">Bairro</label>
                    <input
                      type="text"
                      placeholder="Bairro"
                      className="form-control"
                      name="neighborhood"
                      value={data.address?.neighborhood}
                      onChange={updateAddress}
                    />
                  </div>
                  <div className="col-md-4">
                    <label htmlFor="number">Número</label>
                    <input
                      type="text"
                      placeholder="Número"
                      className="form-control"
                      name="number"
                      value={data.address?.number}
                      onChange={updateAddress}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <label htmlFor="state">Estado</label>
                    <input
                      type="text"
                      placeholder="Estado"
                      className="form-control"
                      name="state"
                      value={data.address?.state}
                      onChange={updateAddress}
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="city">Cidade</label>
                    <input
                      type="text"
                      placeholder="Cidade"
                      className="form-control"
                      name="city"
                      value={data.address?.city}
                      onChange={updateAddress}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="box-button">
            <button className="btn btn-primary col-md-3 c3" onClick={editUser}>
              Salvar
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserEdit;
