import { yupResolver } from "@hookform/resolvers/yup";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import * as yup from "yup";

import Card from "components/card";
import CustomButton from "components/custom-button";
import DataTip from "components/data-tip";
import FormField from "components/FormField";
import InputCheckbox from "components/input-checkbox";
import { numberFloatMask } from "mask";

import "../style.css";
import { useTranslation } from "react-i18next";
import { useLanguageSettings } from "hooks/language-settings";
import { getCUrrencySymbol } from "utils";

Advanced.propTypes = {
  readOnly: PropTypes.bool,
  showSubmitButton: PropTypes.bool,
  userIsAdmin: PropTypes.number,
  callBack: PropTypes.func,
  org_id: PropTypes.number,
  callBackEdit: PropTypes.func,
  paymentPlans: PropTypes.array,
  taxes: PropTypes.object,
  defaultPaymentPlan: PropTypes.number,
  isEvent: PropTypes.bool,
};

export default function Advanced(props) {
  const { currencyInputFormatter } = useLanguageSettings();
  const {
    showSubmitButton,
    readOnly,
    callBack,
    userIsAdmin,
    org_id,
    callBackEdit,
    paymentPlans,
    taxes,
    defaultPaymentPlan,
    children,
    isEvent,
    currency,
  } = props;
  const symbol = getCUrrencySymbol(currency);

  const { t, i18n } = useTranslation("components", { keyPrefix: "advanced" });

  const MAX_PERCENTUAL_PROCESSING_FEE = 100;
  const createFormSchema = () =>
    yup.object({
      _fintxadmon: yup
        .string()
        .required(t("validation-errors.fintxadmon-required")),
      _fintxmincompra: yup
        .string()
        .trim()
        .required(t("validation-errors.fintxmincompra-required")),
      _fintipoCredito: yup
        .string()
        .trim()
        .required(t("validation-errors.fintipoCredito-required")),
      _cusAntecipacao: yup
        .string()
        .trim()
        .required(t("validation-errors.cusAntecipacao-required")),
      _fincustimpProd: yup
        .string()
        .trim()
        .required(t("validation-errors.fincustimpProd-required")),
      _fincustimpCanc: yup
        .string()
        .trim()
        .required(t("validation-errors.fincustimpCanc-required")),
      _cusunitarioposavulso: yup
        .string()
        .trim()
        .required(t("validation-errors.cusunitarioposavulso-required")),
      _custmensalposavulso: yup
        .string()
        .trim()
        .required(t("validation-errors.custmensalposavulso-required")),
      _custunitariobalde: yup
        .string()
        .trim()
        .required(t("validation-errors.custunitariobalde-required")),
      _custunitariomochilas: yup
        .string()
        .trim()
        .required(t("validation-errors.custunitariomochilas-required")),
      _fincustoDebito: yup
        .string()
        .trim()
        .required(t("validation-errors.fincustoDebito-required")),
      _fincustoCredito: yup
        .string()
        .trim()
        .required(t("validation-errors.fincustoCredito-required")),
      _custxpix: yup
        .string()
        .trim()
        .required(t("validation-errors.custxpix-required")),
      _finrecebimentocredito: yup
        .string()
        .trim()
        .required(t("validation-errors.finrecebimentocredito-required")),
      _fincustoAntec: yup
        .string()
        .trim()
        .required(t("validation-errors.fincustoAntec-required")),
      _cusdiariatecnico: yup
        .string()
        .trim()
        .required(t("validation-errors.cusdiariatecnico-required")),
      _fintxrebaite: yup
        .string()
        .trim()
        .required(t("validation-errors.fintxrebaite-required")),
      _cusSaque: yup
        .string()
        .trim()
        .required(t("validation-errors.cusSaque-required")),
      _finpossibilidadesaque: yup
        .string()
        .trim()
        .required(t("validation-errors.finpossibilidadesaque-required")),
      _finlimitesaque: yup
        .string()
        .trim()
        .required(t("validation-errors.finlimitesaque-required")),
      _fincustimpCort: yup
        .string()
        .trim()
        .required(t("validation-errors.fincustimpCort-required")),
      _finfranquiamensalmaqingresso: yup
        .string()
        .trim()
        .required(t("validation-errors.finfranquiamensalmaqingresso-required")),
      _paymentPlan: yup
        .number()
        .default(1)
        .required(t("validation-errors.paymentPlan-required")),
      _fintxprocessamento: yup
        .string()
        .min(0)
        .test("max", t("validation-errors.fintxprocessamento-max"), (val) => {
          return (
            Number(val) <= MAX_PERCENTUAL_PROCESSING_FEE &&
            taxes._fintxprocessamento?.type === "percentual"
          );
        })
        .required(t("validation-errors.fintxprocessamento-required")),
    });
  const [formSchema, setFormSchema] = useState(() => createFormSchema());

  const [changeCheck_fintipoCredito, setChangeCheck_fintipoCredito] =
    useState();
  const [changeCheck_finrecebimentocredito, setChangeCheck_finrecebcredito] =
    useState();

  const {
    register,
    watch,
    setValue,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });

  useEffect(() => {
    setFormSchema(createFormSchema());
    // eslint-disable-next-line
  }, [i18n.language, t]);

  const checkData_fintipoCredito = watch("_fintipoCredito");
  const checkData_finrecebimentocredito = watch("_finrecebimentocredito");

  const onSubmit = (form) => {
    let taxConvert = { taxes: {} };
    const { _paymentPlan } = form;
    for (const key in taxes) {
      if (Object.hasOwnProperty.call(taxes, key)) {
        const taxContent = taxes[key];
        //there are two rates that are not being used. So they don't need to be multiplied by 100.("_fintxdefaultpdv" and "_fintxminpdvprodutor")
        if (
          taxContent.type === "percentual" &&
          key !== "_fintxdefaultpdv" &&
          key !== "_fintxminpdvprodutor"
        ) {
          taxConvert = {
            ...taxConvert,
            taxes: {
              ...taxConvert.taxes,
              [key]: {
                ...taxes[key],
                value: Math.round(parseFloat(form[key]).toFixed(2) * 100, 1),
              },
            },
          };
        } else {
          //Removing "R$" from the all the fixed taxes
          taxConvert = {
            ...taxConvert,
            taxes: {
              ...taxConvert.taxes,
              [key]: {
                //conditional not to apply replace unnecessarily
                ...taxes[key],
                value:
                  key !== "_fintxdefaultpdv" && key !== "_fintxminpdvprodutor"
                    ? +form[key].replace(/\D/g, "")
                    : taxes[key].value,
              },
            },
          };
        }
      }
    }

    if (!org_id) {
      callBack(taxConvert.taxes, _paymentPlan);
    } else {
      callBackEdit(taxConvert.taxes, _paymentPlan, "taxes");
    }
  };

  const userIsNotAdmin = () => {
    return;
  };

  useEffect(() => {
    if (taxes) {
      for (const key in taxes) {
        if (Object.hasOwnProperty.call(taxes, key)) {
          const taxContent = taxes[key];
          if (taxContent.type !== "fixed") {
            setValue(`${key}`, taxContent.value);
          } else {
            setValue(
              `${key}`,
              currencyInputFormatter(taxContent.value, currency)
            );
          }
        }
      }
    }
    if (!Object.keys(taxes).some((tax) => tax === "_fintxprocessamento")) {
      setValue("_fintxprocessamento", 0);
    }
    setValue("_paymentPlan", defaultPaymentPlan || 1);
    // eslint-disable-next-line
  }, [taxes]);

  useEffect(() => {
    setChangeCheck_fintipoCredito(checkData_fintipoCredito);
  }, [checkData_fintipoCredito]);

  useEffect(() => {
    setChangeCheck_finrecebcredito(checkData_finrecebimentocredito);
  }, [checkData_finrecebimentocredito]);

  const verifyPercentualTax = (value) => {
    const parsedValue = numberFloatMask(value);
    if (Number(value) <= MAX_PERCENTUAL_PROCESSING_FEE) {
      if (errors._fintxprocessamento?.message) {
        clearErrors("_fintxprocessamento");
      }
      return parsedValue;
    }
    setError("_fintxprocessamento", {
      message: t("validation-errors.fintxprocessamento-max", {
        "max-value": MAX_PERCENTUAL_PROCESSING_FEE,
      }),
    });
    return parsedValue;
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      {!isEvent && (
        <Row>
          <Col className="header-container">
            <Col>
              <h1 className="header-title">{t("form.header.title")}</h1>
              <p className="header-postitle">{t("form.header.text")}</p>
            </Col>
          </Col>
        </Row>
      )}
      <Card header={t("form.card.header")}>
        <TextH2>{t("form.card.section.ticket")}</TextH2>
        <Form.Row>
          <Col md="6">
            <Form.Row>
              <FormField
                controlId="validation_fintxadmon"
                errorMessage={errors._fintxadmon?.message}
                classNameGroup="col-md-6 col-12"
                label={t("form.card.field-label.convenience-fee")}
              >
                <Form.Control
                  {...register("_fintxadmon")}
                  onChange={(e) =>
                    setValue("_fintxadmon", numberFloatMask(e.target.value))
                  }
                  type="text"
                  maxLength={5}
                  name="_fintxadmon"
                  className="form-control"
                  isInvalid={errors._fintxadmon?.message}
                  readOnly={readOnly}
                />
              </FormField>
              <FormField
                classNameGroup="col-md-6 col-12"
                label={t("form.card.field-label.minimum-convenience-fee", {
                  symbol,
                })}
                errorMessage={errors._fintxmincompra?.message}
                controlId="validation_fintxmincompra"
              >
                <Form.Control
                  type="text"
                  name="_fintxmincompra"
                  {...register("_fintxmincompra")}
                  onChange={(e) =>
                    setValue(
                      "_fintxmincompra",
                      currencyInputFormatter(e.target.value, currency)
                    )
                  }
                  className="form-control col-12"
                  readOnly={readOnly}
                  isInvalid={errors._fintxmincompra?.message}
                />
              </FormField>
            </Form.Row>
            <Form.Row>
              <FormField
                classNameGroup="col-md-6 col-12"
                label={t("form.card.field-label.credit-receiving-form")}
                errorMessage={errors._fintipoCredito?.message}
                controlId="validation_fintipoCredito"
              >
                <Form.Row className="row">
                  <InputCheckbox
                    active={
                      changeCheck_finrecebimentocredito === 32 ||
                      changeCheck_finrecebimentocredito === "D + 32"
                        ? true
                        : null
                    }
                    disabled={!userIsAdmin}
                    name="D + 32"
                    value="D + 32"
                    onClick={(e) => {
                      if (userIsAdmin) {
                        setValue("_finrecebimentocredito", e);
                      } else {
                        userIsNotAdmin();
                      }
                    }}
                    className="col-md-6 mb-1"
                  />
                  <InputCheckbox
                    active={
                      changeCheck_finrecebimentocredito === 2 ||
                      changeCheck_finrecebimentocredito === "D + 2"
                        ? true
                        : null
                    }
                    disabled={!userIsAdmin}
                    name="D + 2"
                    value="D + 2"
                    onClick={(e) => {
                      if (userIsAdmin) {
                        setValue("_finrecebimentocredito", e);
                      } else {
                        userIsNotAdmin();
                      }
                    }}
                    className="col-md-6 mb-1"
                  />
                </Form.Row>
              </FormField>
              <FormField
                classNameGroup="col-md-6 col-12"
                errorMessage={errors._cusAntecipacao?.message}
                label={
                  <>
                    <Form.Row className="row ml-1">
                      {t("form.card.field-label.anticipation-rate")}
                      <DataTip
                        tipContent={t("form.card.tip.ticket-anticipation-rate")}
                      />
                    </Form.Row>
                  </>
                }
                classNameLabel="form-label"
              >
                <Form.Control
                  type="text"
                  name="_cusAntecipacao"
                  {...register("_cusAntecipacao")}
                  maxLength={5}
                  onChange={(e) =>
                    setValue("_cusAntecipacao", numberFloatMask(e.target.value))
                  }
                  ty
                  readOnly={readOnly}
                  isInvalid={errors._cusAntecipacao?.message}
                  className="form-control col-12"
                />
              </FormField>
            </Form.Row>
            <Form.Row>
              <FormField
                classNameGroup="col-md-6 col-12"
                label={t("form.card.field-label.print-cost", { symbol })}
                errorMessage={errors._fincustimpProd?.message}
                controlId="validation_fincustimpProd"
              >
                <Form.Control
                  type="text"
                  name="_fincustimpProd"
                  {...register("_fincustimpProd")}
                  onChange={(e) =>
                    setValue(
                      "_fincustimpProd",
                      currencyInputFormatter(e.target.value, currency)
                    )
                  }
                  className="form-control col-12"
                  isInvalid={errors._fincustimpProd?.message}
                  readOnly={readOnly}
                />
              </FormField>
              <FormField
                classNameGroup="col-md-6 col-12"
                label={t("form.card.field-label.cancellation-cost", { symbol })}
                errorMessage={errors._fincustimpCanc?.message}
                controlId="validation_fincustimpCanc"
              >
                <Form.Control
                  type="text"
                  name="_fincustimpCanc"
                  className="form-control col-12"
                  {...register("_fincustimpCanc")}
                  onChange={(e) =>
                    setValue(
                      "_fincustimpCanc",
                      currencyInputFormatter(e.target.value, currency)
                    )
                  }
                  isInvalid={errors._fincustimpProd?.message}
                  readOnly={readOnly}
                />
              </FormField>
            </Form.Row>
            <Form.Row>
              <FormField
                classNameGroup="col-md-6 col-12"
                label={`${t("form.card.field-label.processingFee")} ${
                  taxes._fintxprocessamento?.type === "percentual"
                    ? "(%)"
                    : `(${symbol})`
                }`}
                errorMessage={errors._fintxprocessamento?.message}
                controlId="validation_fintxprocessamento"
              >
                <Form.Control
                  type="text"
                  {...register("_fintxprocessamento")}
                  maxLength={
                    taxes._fintxprocessamento?.type === "percentual" && 5
                  }
                  onChange={(event) => {
                    if (taxes._fintxprocessamento?.type === "percentual") {
                      setValue(
                        "_fintxprocessamento",
                        verifyPercentualTax(event.target.value)
                      );
                    } else {
                      setValue(
                        "_fintxprocessamento",
                        currencyInputFormatter(event.target.value, currency)
                      );
                    }
                  }}
                  name="_fintxprocessamento"
                  className="form-control col-12"
                  readOnly={readOnly}
                  isInvalid={errors._fintxprocessamento?.message}
                />
              </FormField>
              <FormField
                classNameGroup="col-md-6 col-12"
                errorMessage={errors._finpossibilidadesaque?.message}
                controlId="validation_finpossibilidadesaque"
                label={
                  <>
                    <Form.Row className="row ml-1">
                      {t("form.card.field-label.withdrawal-possibility")}
                      <DataTip
                        tipContent={t("form.card.tip.withdrawal-possibility")}
                      />
                    </Form.Row>
                  </>
                }
              >
                <Form.Row className="ml-1 mt-1 p-0">
                  <InputCheck
                    className="mb-2 mr-2"
                    defaultChecked={!!taxes._finpossibilidadesaque?.value}
                    disabled={!userIsAdmin ? true : false}
                    name="_finpossibilidadesaque"
                    onChange={(e) => {
                      setValue(
                        "_finpossibilidadesaque",
                        e.target.checked ? 1 : 0
                      );
                    }}
                  />
                  <TextH3>
                    {t("form.card.field-label.enable-withdrawal")}
                  </TextH3>
                </Form.Row>
              </FormField>
            </Form.Row>
          </Col>
          <Col md="6">
            <Form.Row>
              <FormField
                classNameGroup="col-md-6 col-12"
                label={t("form.card.field-label.rebate-rate")}
                errorMessage={errors._fintxrebaite?.message}
                controlId="validation_fintxrebaite"
              >
                <Form.Control
                  type="text"
                  name="_fintxrebaite"
                  maxLength={5}
                  {...register("_fintxrebaite")}
                  onChange={(e) =>
                    setValue("_fintxrebaite", numberFloatMask(e.target.value))
                  }
                  readOnly={readOnly}
                  isInvalid={errors._fintxrebaite?.message}
                  className="form-control col-12"
                />
              </FormField>
              <FormField
                classNameGroup="col-md-6 col-12"
                errorMessage={errors._cusSaque?.message}
                label={t("form.card.field-label.withdrawal-cost", { symbol })}
                controlId="validation_cusSaque"
              >
                <Form.Control
                  type="text"
                  name="_cusSaque"
                  {...register("_cusSaque")}
                  onChange={(e) =>
                    setValue(
                      "_cusSaque",
                      currencyInputFormatter(e.target.value, currency)
                    )
                  }
                  isInvalid={errors._cusSaque?.message}
                  className="form-control col-12"
                  readOnly={readOnly}
                />
              </FormField>
            </Form.Row>
            <Form.Row className="row">
              <FormField
                classNameGroup="col-md-6 col-12"
                label={t("form.card.field-label.select-payment-plan")}
                errorMessage={errors._paymentPlan?.message}
                controlId="validation_paymentPlan"
              >
                <Form.Control
                  as="select"
                  name="_paymentPlan"
                  {...register("_paymentPlan")}
                  onChange={(e) => setValue("_paymentPlan", e.target.value)}
                  disabled={!userIsAdmin}
                >
                  {paymentPlans.map((paymentPlan, index) => (
                    <option
                      value={paymentPlan.id}
                      key={index}
                      defaultValue={defaultPaymentPlan === paymentPlan.id}
                    >
                      {paymentPlan.name}
                    </option>
                  ))}
                </Form.Control>
              </FormField>
              <FormField
                classNameGroup="col-md-6 col-12"
                label={t("form.card.field-label.daily-withdrawal-limit", {
                  symbol,
                })}
                errorMessage={errors._finlimitesaque?.message}
                controlId="validation_finlimitesaque"
              >
                <Form.Control
                  type="text"
                  name="_finlimitesaque"
                  {...register("_finlimitesaque")}
                  onChange={(e) =>
                    setValue(
                      "_finlimitesaque",
                      currencyInputFormatter(e.target.value, currency)
                    )
                  }
                  className="form-control col-12"
                  readOnly={readOnly}
                />
              </FormField>
            </Form.Row>
            <Form.Row>
              <FormField
                classNameGroup="col-md-6 col-12"
                label={t("form.card.field-label.courtesy-print-cost", {
                  symbol,
                })}
                errorMessage={errors._fincustimpCort?.message}
                controlId="validation_fincustimpCort"
              >
                <Form.Control
                  type="text"
                  name="_fincustimpCort"
                  {...register("_fincustimpCort")}
                  onChange={(e) =>
                    setValue(
                      "_fincustimpCort",
                      currencyInputFormatter(e.target.value, currency)
                    )
                  }
                  isInvalid={errors._fincustimpCort?.message}
                  className="form-control col-12"
                  readOnly={readOnly}
                />
              </FormField>
              <FormField
                classNameGroup="col-md-6 col-12"
                label={t("form.card.field-label.monthly-machine-quota", {
                  symbol,
                })}
                errorMessage={errors._finfranquiamensalmaqingresso?.message}
                controlId="validation_finfranquiamensalmaqingresso"
              >
                <Form.Control
                  type="text"
                  name="_finfranquiamensalmaqingresso"
                  {...register("_finfranquiamensalmaqingresso")}
                  onChange={(e) =>
                    setValue(
                      "_finfranquiamensalmaqingresso",
                      currencyInputFormatter(e.target.value, currency)
                    )
                  }
                  isInvalid={errors._finfranquiamensalmaqingresso?.message}
                  className="form-control col-12"
                  readOnly={readOnly}
                />
              </FormField>
            </Form.Row>
            <Form.Row>{children}</Form.Row>
          </Col>
        </Form.Row>
        <Hr />
        <TextH2>{t("form.card.section.a-and-b")}</TextH2>
        <Form.Row>
          <FormField
            classNameGroup="col-md-3 col-12"
            label={t("form.card.field-label.standalone-smart-pos-cost", {
              symbol,
            })}
            errorMessage={errors._cusunitarioposavulso?.message}
            controlId="validation_cusunitarioposavuls"
          >
            <Form.Control
              type="text"
              name="_cusunitarioposavulso"
              {...register("_cusunitarioposavulso")}
              onChange={(e) =>
                setValue(
                  "_cusunitarioposavulso",
                  currencyInputFormatter(e.target.value, currency)
                )
              }
              isInvalid={errors._cusunitarioposavulso?.message}
              className="form-control col-12"
              readOnly={readOnly}
            />
          </FormField>
          <FormField
            classNameGroup="col-md-3 col-12"
            label={t("form.card.field-label.smart-pos--monthly-fee", {
              symbol,
            })}
            errorMessage={errors._custmensalposavulso?.message}
            controlId="validation_custmensalposavulso"
          >
            <Form.Control
              type="text"
              name="_custmensalposavulso"
              {...register("_custmensalposavulso")}
              onChange={(e) =>
                setValue(
                  "_custmensalposavulso",
                  currencyInputFormatter(e.target.value, currency)
                )
              }
              isInvalid={errors._custmensalposavulso?.message}
              className="form-control col-12"
              readOnly={readOnly}
            />
          </FormField>
          <FormField
            classNameGroup="col-md-3 col-12"
            label={t("form.card.field-label.bucket-rental-cost", { symbol })}
            errorMessage={errors._custunitariobalde?.message}
            controlId="validation_custunitariobalde"
          >
            <Form.Control
              type="text"
              name="_custunitariobalde"
              {...register("_custunitariobalde")}
              onChange={(e) =>
                setValue(
                  "_custunitariobalde",
                  currencyInputFormatter(e.target.value, currency)
                )
              }
              isInvalid={errors._custunitariobalde?.message}
              className="form-control col-12"
              readOnly={readOnly}
            />
          </FormField>
          <FormField
            classNameGroup="col-md-3 col-12"
            label={t("form.card.field-label.backpack-rental-cost", { symbol })}
            errorMessage={errors._custunitariomochilas?.message}
            controlId="validation_custunitariomochilas"
          >
            <Form.Control
              type="text"
              name="_custunitariomochilas"
              {...register("_custunitariomochilas")}
              onChange={(e) =>
                setValue(
                  "_custunitariomochilas",
                  currencyInputFormatter(e.target.value, currency)
                )
              }
              isInvalid={errors._custunitariomochilas?.message}
              className="form-control col-12"
              readOnly={readOnly}
            />
          </FormField>
        </Form.Row>
        <Hr />
        <TextH2 className="mt-4">{t("form.card.section.generic")}</TextH2>
        <Form.Row>
          <FormField
            classNameGroup="col-md-4 col-12"
            label={t("form.card.field-label.debit-rate")}
            errorMessage={errors._fincustoDebito?.message}
            controlId="validation_fincustoDebito"
          >
            <Form.Control
              type="text"
              name="_fincustoDebito"
              maxLength={5}
              {...register("_fincustoDebito")}
              onChange={(e) =>
                setValue("_fincustoDebito", numberFloatMask(e.target.value))
              }
              isInvalid={errors._fincustoDebito?.message}
              className="form-control col-12"
              readOnly={readOnly}
            />
          </FormField>
          <FormField
            classNameGroup="col-md-4 col-12"
            label={t("form.card.field-label.credit-rate")}
            errorMessage={errors._fincustoCredito?.message}
            controlId="validation_fincustoCredito"
          >
            <Form.Control
              {...register("_fincustoCredito")}
              type="text"
              maxLength={5}
              name="_fincustoCredito"
              onChange={(e) =>
                setValue("_fincustoCredito", numberFloatMask(e.target.value))
              }
              isInvalid={errors._fincustoCredito?.message}
              className="form-control col-12"
              readOnly={readOnly}
            />
          </FormField>
          <FormField
            classNameGroup="col-md-4 col-12"
            label={t("form.card.field-label.pix-rate")}
            errorMessage={errors._custxpix?.message}
            controlId="validation_custxpix"
          >
            <Form.Control
              type="text"
              name="_custxpix"
              maxLength={5}
              {...register("_custxpix")}
              onChange={(e) =>
                setValue("_custxpix", numberFloatMask(e.target.value))
              }
              isInvalid={errors._custxpix?.message}
              className="form-control col-12"
              readOnly={readOnly}
            />
          </FormField>
        </Form.Row>
        <Form.Row>
          <FormField
            classNameGroup="col-md-4 col-12"
            label={t("form.card.field-label.credit-receiving-method")}
            errorMessage={errors._finrecebimentocredito?.message}
            controlId="validation_finrecebimentocredito"
          >
            <Form.Row className="row">
              <InputCheckbox
                active={
                  changeCheck_fintipoCredito === 32 ||
                  changeCheck_fintipoCredito === "D + 32"
                    ? true
                    : null
                }
                disabled={!userIsAdmin}
                name="D + 32"
                value="D + 32"
                onClick={(e) => {
                  if (userIsAdmin) {
                    setValue("_fintipoCredito", e);
                  } else {
                    userIsNotAdmin();
                  }
                }}
                className="col-md-6 mb-1"
              />
              <InputCheckbox
                active={
                  changeCheck_fintipoCredito === 2 ||
                  changeCheck_fintipoCredito === "D + 2"
                    ? true
                    : null
                }
                disabled={!userIsAdmin}
                name="D + 2"
                value="D + 2"
                onClick={(e) => {
                  if (userIsAdmin) {
                    setValue("_fintipoCredito", e);
                  } else {
                    userIsNotAdmin();
                  }
                }}
                className="col-md-6 mb-1"
              />
            </Form.Row>
          </FormField>
          <FormField
            classNameGroup="col-md-4 col-12"
            errorMessage={errors._fincustoAntec?.message}
            label={
              <>
                <Form.Row className="row ml-1">
                  {t("form.card.field-label.anticipation-rate")}
                  <DataTip tipContent={t("form.card.tip.anticipation-rate")} />
                </Form.Row>
              </>
            }
            controlId="validation_fincustoAntec"
          >
            <Form.Control
              type="text"
              readOnly={readOnly}
              maxLength={5}
              name="_fincustoAntec"
              onChange={(e) =>
                setValue("_fincustoAntec", numberFloatMask(e.target.value))
              }
              {...register("_fincustoAntec")}
              className="form-control col-12"
            />
          </FormField>
          <FormField
            classNameGroup="col-md-4 col-12"
            label={t("form.card.field-label.technician-daily-cost", { symbol })}
            errorMessage={errors._cusdiariatecnico?.message}
            controlId="validation_cusdiariatecnico"
          >
            <Form.Control
              type="text"
              name="_cusdiariatecnico"
              {...register("_cusdiariatecnico")}
              onChange={(e) =>
                setValue(
                  "_cusdiariatecnico",
                  currencyInputFormatter(e.target.value, currency)
                )
              }
              isInvalid={errors._cusdiariatecnico?.message}
              className="form-control col-12"
              readOnly={readOnly}
            />
          </FormField>
        </Form.Row>
      </Card>
      {!showSubmitButton && (
        <Form.Row className="justify-content-end">
          <Col md="3" sm="12" className="mb-2">
            <CustomButton
              buttonType
              className="btn btn-primary col-12"
              text={t(
                org_id ? "form.card.button.save" : "form.card.button.next"
              )}
            />
          </Col>
        </Form.Row>
      )}
    </Form>
  );
}
//////////////////// COMPONENTs ////////////////////

const Hr = styled.hr``;

const InputCheck = styled.input.attrs({
  type: "checkbox",
})`
  cursor: pointer;
`;

const TextH2 = styled.h2`
  font-size: 0.85rem;
  color: #4f6c7c;
  font-weight: 600;
`;

const TextH3 = styled.h3`
  font-size: 13px;
  color: #8fa5b2;
  font-weight: 300;
`;
