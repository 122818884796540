import {
  faGlobe,
  faImage,
  faTimesCircle,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { endOfDay, isSameDay, startOfDay } from "date-fns";
import JoditEditor from "jodit-react";
import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import { InputGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import NumberFormat from "react-number-format";
import { isValidPhoneNumber } from "react-phone-number-input/max";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import Switch from "react-switch";
import Swal from "sweetalert2";

import ButtonDashed from "components/buttonDashed";
import CollaboratorModal from "components/collaborator-modal";
import EditCategorySelector from "components/editCategorySelector";
import PhoneNumber from "components/input-phone-number";
import Loading from "components/loading";
import OrganizationsInput from "components/organizations-input";
import PageHeader from "components/page-header";
import PaymentButtons from "components/paymentbuttons";
import SponsorCard from "components/sponsorCard";
import SuperRadioButton from "components/super-radiobutton";
import csswelcome from "cssdinamico";
import useQuery from "customerHooks/useQuery";
import { numberMask } from "mask";
import Advanced from "pages/common-area/organizacoes/organizacao/advanced";
import api from "services/api";
import { currencies } from "services/constants";
import { channels, channelSelectStyles, COLORS } from "services/constants";
import { ImageHandlerService } from "services/image-handler";
import New_api from "services/new-api";
import { UtilsHelper } from "services/utils";
import { ValidationsHelper } from "services/validations";
import { getAllTimezones } from "utils";

import { ReactComponent as AddCircle } from "../../../../assets/icons/addCircle.svg";
import { ReactComponent as DollarIcon } from "../../../../assets/icons/dollar-coin-solid.svg";
import { ReactComponent as ExclamationMark } from "../../../../assets/icons/ExclamationMark.svg";
import { ReactComponent as TimezoneIcon } from "../../../../assets/icons/timezoneGlobal.svg";
import SponsorsModal from "../event-create/event-create-steps/event-create-step-1/sponsorsModal";

import "./styles.css";
import "react-google-places-autocomplete/dist/assets/index.css";
import { DatePicker } from "components/datepicker";
import DataTip from "components/data-tip";
import EventAddress from "components/event-location";
import { GroupHeader } from "../event-create/event-create-steps/event-create-step-1/groupHeader";
import { changeState } from "components/subheader";

const linkEmpresa = process.env.REACT_APP_LINK_EMPRESA;

const CURRENT_PAGE = 1;

const PER_PAGE = 10;

const config = {
  buttons: [
    "source",
    "|",
    "bold",
    "strikethrough",
    "underline",
    "italic",
    "|",
    "ul",
    "ol",
    "|",
    "outdent",
    "indent",
    "|",
    "font",
    "fontsize",
    "brush",
    "paragraph",
    "|",
    "image",
    "video",
    "table",
    "link",
    "|",
    "align",
    "undo",
    "redo",
  ],
};

const dontShowSaveBtn = [4, 6, 7];

const currenciesOptions = currencies.map(({ formatted, value }, index) => ({
  id: index,
  label: formatted,
  value,
}));

const timezoneOptions = getAllTimezones().map(
  ({ formatted, timezone }, index) => ({
    id: index,
    label: formatted,
    value: timezone,
  })
);

const user = JSON.parse(localStorage.getItem("user"));
const EventEdit = () => {
  const { t } = useTranslation();
  const urlParams = new URLSearchParams(window.location.search);
  const eventid = urlParams.get("event_id");
  const history = useHistory();
  const query = useQuery();
  const position = query.get("position");
  const [categories, setCategories] = useState([
    { position: 1, mainCategory: { label: "", value: 0 }, sub: [] },
    { position: 2, mainCategory: { label: "", value: 0 }, sub: [] },
  ]);

  const [showSponsorModal, setShowSponsorModal] = useState("modal-hidden");
  const [bannerloading, setBannerLoading] = useState(false);
  const [banner, setBanner] = useState("");
  const [thumbloading, setThumbLoading] = useState(false);
  const [thumb, setThumb] = useState("");
  const [event, setEvent] = useState();
  const [strokeColor, setStrokeColor] = useState("#2075F4");
  const [posTypePayments, setPosTypePayments] = useState([]);
  const [onlineTypePayments, setOnlineTypePayments] = useState([]);
  const [organizationName, setOrganizationName] = useState();
  const [nome, setNome] = useState("");
  const [slug, setSlug] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [isOnline, setIsOnline] = useState();
  const [isPublic, setIsPublic] = useState();
  const [taxes, setTaxes] = useState();
  const [ageRange, setAgeRange] = useState();
  const [ticketNomenclature, setTicketNomenclature] = useState();
  const [description, setDescription] = useState();

  const [tags, setTags] = useState([]);
  const [posTypes, setPosTypes] = useState([]);
  const [sponsor, setSponsor] = useState({});
  const [sponsors, setSponsors] = useState([]);
  const [onlineTypes, setOnlineTypes] = useState([]);
  const [changeCard, setChange] = useState(0);
  const [sailsLimit, setLimit] = useState();
  const [mostraUSer, setMostraUser] = useState(false);
  const [paymentPlans, setPaymentPlans] = useState([]);
  const [eventLink, setEventLink] = useState("");
  const [remoteBanner, setRemoteBanner] = useState();
  const [remoteThumbnail, setRemoteThumbnail] = useState();
  const [collaborators, setCollaborators] = useState([]);
  const [loadingCollaborators, setLoadingCollaborators] = useState(true);
  const [showCollaboratorModal, setShowCollaboratorModal] = useState(false);
  const [ab_max_installments, setAb_max_installments] = useState(1);
  const [ab_interest_rate, setab_interest_rate] = useState(1);
  const [max_online_order_tickets, set_max_online_order_tickets] = useState(5);
  const [max_pos_order_tickets, set_max_pos_order_tickets] = useState(50);
  const [expirationMinutes, setExpirationMinutes] = useState(15);
  const [cartRecoveryMinutes, setCartRecoveryMinutes] = useState(120);
  const [antifraudType, setAntifraudType] = useState("show");
  const [antifraudRiskLevel, setAntifraudRiskLevel] = useState("low");
  const [forceThreeds, setForceThreeds] = useState(false);
  const [hoursToEnableThreeds, setHoursToEnableThreeds] = useState(48);
  const [currency, setCurrency] = useState("");
  const [timezone, setTimezone] = useState("");
  const [maxSensibleMethodsAttempts, setMaxSensibleMethodsAttempts] =
    useState(3);
  const [locations, setLocation] = useState({
    location: {
      id: "",
      name: "",
      formatted_address: "",
      number: "",
      complement: "",
      street: "",
      neighborhood: "",
      city: "",
      state: "",
      zip_code: "",
    },
  });
  const [fees, setFees] = useState({});
  const switchStyles2 = {
    control: (control) => ({
      ...control,
      cursor: "pointer",
      boxShadow: "none",
      width: "100%",
      padding: "0.1rem",
      borderColor: "#ced4da",
      borderRadius: "0 0.375rem 0.375rem 0",
    }),
    placeholder: (placeholder) => ({
      ...placeholder,
      color: "#b1cce6",
    }),
    option: (option, state) => ({
      ...option,
      color: !state.isSelected ? "#6e84a3" : "white",
      cursor: "pointer",
    }),
    singleValue: (singleValue) => ({
      ...singleValue,
      color: "#6e84a3",
    }),
    dropdownIndicator: (dropdownIndicator) => ({
      ...dropdownIndicator,
      color: "#6e84a3",
    }),
  };

  const [maxOnlineInstallments, setMaxOnlineInstallments] = useState(12);
  const [eventPaymentPlanId, setEventPaymentPlanId] = useState();
  const [groupTicketsIntoSectors, setGroupTicketsIntoSectors] = useState(false);
  const [enableAttendeesCheckoutAutofill, setEnableAttendeesCheckoutAutofill] =
    useState(true);
  const [communicationChannel, setCommunicationChannel] = useState("");
  const [communicationChannelType, setCommunicationChannelType] = useState("");

  useEffect(() => {
    if (user && user.is_admin) {
      setMostraUser(true);
    }

    csswelcome();
    carregaFormasPagamento();

    if (!event) {
      api
        .get(`users/events/categories-and-organizations`)
        .then(() => {
          if (eventid) {
            carregaEvento(eventid);
            loadCollaborators(eventid);
            loadPaymentPlans();
          }
        })
        .catch(() => {
          Swal.fire({
            title: t("global:errors.ops"),
            icon: "error",
            text: t("global:errors.internalServerError"),
            confirmButtonText: "Ok",
          });
        });
    } else {
      if (position) {
        setChange(+position);
      } else {
        setChange(0);
      }
    }
    // eslint-disable-next-line
  }, [eventid, position]);

  const handleSponsorsModal = (status) => {
    if (sponsors.length === 10 && status === "modal-show") {
      Swal.fire({
        title: "Limite de parceiros",
        text: "Existe limite de 10 parceiro. Para adicionar novo parceiro terá que excluir um da listagem.",
        confirmButtonText: "Fechar",
        confirmButtonColor: "#5B4299",
        icon: "warning",
      });
      setShowSponsorModal("modal-hidden");
    } else {
      setShowSponsorModal(status);
      setSponsor(null);
    }
  };

  const loadSponsors = (id) => {
    document.getElementById("loading").classList.remove("esconde");

    New_api.get(`events/${id}/event-sponsors`, {
      params: {
        page: CURRENT_PAGE,
        per_page: PER_PAGE,
      },
    })
      .then((res) => {
        setSponsors(res.data.data);
      })
      .catch(() => {
        Swal.fire({
          title: "Ops!",
          icon: "error",
          text: "Não foi possível carregar o apoiador/patrocinador. Tente novamente.",
          confirmButtonText: "Ok",
        });
      })
      .finally(() =>
        document.getElementById("loading").classList.add("esconde")
      );
  };

  const sponsorAdd = (newSponsor) => {
    const payload = {
      order: sponsors.length + 1,
      ...newSponsor,
    };

    document.getElementById("loading").classList.remove("esconde");
    New_api.post(`events/${event.id}/event-sponsors`, payload)
      .then(() => {
        Swal.fire({
          title: "Sucesso",
          icon: "success",
          text: `O parceiro ${payload.name} foi criado com sucesso.`,
          confirmButtonText: "Ok",
        });

        loadSponsors(event.id);
      })
      .catch((err) => {
        Swal.fire({
          title: "Não foi possível criar um novo parceiro. Tente novamente.",
          icon: "error",
          confirmButtonColor: "#5B4299",
          confirmButtonText: "Ok",
        });
      })
      .finally(() =>
        document.getElementById("loading").classList.add("esconde")
      );
  };

  const userInformations = JSON.parse(localStorage.getItem("user"));
  const readOnlyTaxes = userInformations.is_admin ? false : true;

  const sponsorEdit = (sponsorData) => {
    document.getElementById("loading").classList.remove("esconde");
    New_api.put(`event-sponsors/${sponsorData.id}`, sponsorData)
      .then((res) => {
        Swal.fire({
          title: "Sucesso",
          icon: "success",
          text: `O parceiro ${res.data.name} foi atualizado com sucesso.`,
          confirmButtonText: "Fechar",
          confirmButtonColor: "#5B4299",
        });

        loadSponsors(event.id);
      })
      .catch((err) => {
        Swal.fire({
          title: "Não foi possível atualizar o parceiro. Tente novamente.",
          icon: "error",
          confirmButtonColor: "#5B4299",
          confirmButtonText: "Ok",
        });
      })
      .finally(() =>
        document.getElementById("loading").classList.add("esconde")
      );
  };

  const handleSponsorModalForm = (form, action) => {
    if (action === "edit") {
      sponsorEdit(form);
    } else {
      sponsorAdd(form);
    }
  };

  const carregaEvento = (eventid) => {
    api
      .get(`event/details/${eventid}`)
      .then((res) => {
        document.getElementById("loading").classList.add("esconde");
        let evento = res.data;
        setEvent(evento);
        let dataIni = new Date(evento.start_date);
        let dataEnd = new Date(evento.end_date);
        let limit = evento.sails_limit ? new Date(evento.sails_limit) : "";

        if (position) {
          setChange(+position);
        }

        if (!evento.currency && !evento.timezone) {
          New_api.get("settings/locale").then(
            ({ data: { timezone, currency } }) => {
              setCurrency(currency);
              setTimezone(timezone);
            }
          );
        }

        if (evento.organizations) {
          setOrganizationName({
            label: evento.organizations.name,
            value: evento.organizations.id,
          });
        } else {
          setOrganizationName(null);
        }
        setNome(evento.name);
        setSlug(evento.slug);
        setSponsors(evento.sponsors);
        setStartDate(dataIni);
        setEndDate(dataEnd);
        setIsOnline(evento.is_online);
        setIsPublic(evento.is_public);
        setTaxes(evento.taxes);
        setAgeRange(evento.age_range);
        setTicketNomenclature(evento.ticket_nomenclature);
        setDescription(evento.description);
        setBanner(evento.banner);
        setThumb(evento.thumb);
        setLimit(limit);
        setAb_max_installments(evento.ab_max_installments);
        setab_interest_rate((evento.ab_interest_rate || 0) / 100);
        set_max_online_order_tickets(evento.max_online_order_tickets);
        set_max_pos_order_tickets(evento.max_pos_order_tickets);
        setLocation({
          location: {
            id: evento.locations[0].id,
            name: evento.locations[0].name,
            formatted_address: `${evento.locations[0].street}, ${evento.locations[0].number} - ${evento.locations[0].neighborhood}, ${evento.locations[0].city}, ${evento.locations[0].state} - ${evento.locations[0].zip_code}`,
            number: evento.locations[0].number,
            complement: evento.locations[0].complement,
            street: evento.locations[0].street,
            neighborhood: evento.locations[0].neighborhood,
            city: evento.locations[0].city,
            state: evento.locations[0].state,
            zip_code: evento.locations[0].zip_code,
          },
        });
        setPosTypes(
          evento.type_payments
            .filter((item) => item.mode === "pos")
            .map((item) => item.id)
        );
        setOnlineTypes(
          evento.type_payments
            .filter((item) => item.mode === "online")
            .map((item) => item.id)
        );
        setCurrency(evento.currency);
        setExpirationMinutes(evento.expiration_minutes);
        setCartRecoveryMinutes(evento.cart_recovery_minutes);
        setAntifraudRiskLevel(evento.fraud_risk_level);
        setAntifraudType(evento.antifraud_type);
        setMaxSensibleMethodsAttempts(evento.max_sensible_methods_attempts);
        setForceThreeds(evento.force_threeds === 1);
        setHoursToEnableThreeds(evento.hours_to_enable_threeds);
        setMaxOnlineInstallments(evento.max_online_installments);
        setEventPaymentPlanId(evento.payments_plan_id);
        setGroupTicketsIntoSectors(!!evento.group_tickets_into_sectors);
        setEventLink(evento.link_other);
        setCommunicationChannel(evento.communication_channel);
        setCommunicationChannelType(evento.communication_channel_type);
        setTimezone(evento.timezone);
        setEnableAttendeesCheckoutAutofill(
          evento.enable_attendees_checkout_autofill
        );

        if (!evento.taxes_list || evento.taxes_list.length === 0) {
          try {
            api
              .get(`organization/show/${evento.organization_id}`)
              .then((res) => {
                setFees(res.data.organization.taxes);
              });
          } catch (error) {
            throw error;
          }
        } else {
          setFees(evento.taxes_list);
        }

        if (evento.categories.length > 0) {
          const categoryData = evento.categories.map((category, index) => {
            return {
              position: index + 1,
              mainCategory: { value: category.id, label: category.name },
              sub:
                category.subCategories.length > 0
                  ? category.subCategories.map((subcategory) => ({
                      label: subcategory.name,
                      value: subcategory.id,
                    }))
                  : [],
            };
          });

          if (categoryData.length === 1) {
            categoryData.push({
              position: 2,
              mainCategory: { value: 0, label: "" },
              sub: [],
            });
          }

          setCategories(categoryData);
        }
        if (evento && evento.tags) {
          /* eslint-disable-next-line no-eval */
          let arraytag = eval(evento.tags);

          if (typeof arraytag === "string") {
            arraytag = evento.tags
              .replace(/\[/, "")
              .replace(/\]/, "")
              .split(",");
          }

          arraytag = arraytag
            .map((tag) => tag.replace(/[\\"]/g, ""))
            .filter((tag) => tag.length > 0);

          setTags(arraytag);
        }

        if (evento.thumb) {
          setRemoteThumbnail(
            ImageHandlerService.getImageFromUrl(evento.thumb, {
              format: "webp",
              resize: { width: 100, fit: "cover" },
            })
          );
        }

        if (evento.banner) {
          setRemoteBanner(
            ImageHandlerService.getImageFromUrl(evento.banner, {
              format: "webp",
              resize: { width: 128, fit: "cover" },
            })
          );
        }
      })
      .catch(() => {
        Swal.fire({
          title: t("global:errors.ops"),
          icon: "error",
          text: t("global:errors.internalServerError"),
          confirmButtonText: "Ok",
        });
      });
  };

  const loadPaymentPlans = () => {
    api
      .get("paymentplans")
      .then((res) => {
        setPaymentPlans(res.data);
      })
      .catch(() => {
        Swal.fire({
          title: t("global:errors.ops"),
          icon: "error",
          text: t("global:errors.internalServerError"),
          confirmButtonText: "Ok",
        });
      });
  };

  const editCategoryCallback = (categoryData) => {
    setEvent({ ...event, categories: categoryData });
    setCategories(categoryData);
  };

  const carregaFormasPagamento = () => {
    api
      .get("type_payments")
      .then((res) => {
        setPosTypePayments(
          res.data.filter(
            (item) => item.mode === "common" || item.mode === "pos"
          )
        );
        setOnlineTypePayments(
          res.data.filter(
            (item) => item.mode === "common" || item.mode === "online"
          )
        );
      })
      .catch(() => {
        Swal.fire({
          title: t("global:errors.ops"),
          icon: "error",
          text: t("global:errors.internalServerError"),
          confirmButtonText: "Ok",
        });
      });
  };

  const handlePaymentType = (typeId, mode) => {
    let items = mode === "online" ? onlineTypes : posTypes;
    const index = items.findIndex((id) => typeId === id);

    if (index !== -1) {
      items.splice(index, 1);
    } else {
      items.push(typeId);
    }

    if (mode === "online") {
      setOnlineTypes([...items]);
    } else {
      setPosTypes([...items]);
    }
  };

  const uploadImage = (event, input_id) => {
    const isBanner = input_id === "event-banner";
    const formData = new FormData();

    if (isBanner) {
      setBannerLoading(true);
    } else {
      setThumbLoading(true);
    }

    formData.append("image", event.target.files[0]);

    api
      .post("uploads?local=eventos", formData, {
        headers: { "content-type": "multipart/form-data" },
      })
      .then((res) => {
        if (isBanner) {
          setBanner(res.data.url);
          setRemoteBanner(res.data.url);
        } else {
          setThumb(res.data.url);
          setRemoteThumbnail(res.data.url);
        }
      })
      .catch(() => {
        Swal.fire({
          title: t("global:errors.ops"),
          icon: "error",
          text: t("global:errors.internalServerError"),
          confirmButtonText: "Ok",
        });
      })
      .finally(() => {
        if (isBanner) {
          setBannerLoading(false);
        } else {
          setThumbLoading(false);
        }
      });
  };

  const saveEvent = (savedFees, paymentPlanId) => {
    let selectedFees;
    if (savedFees) {
      selectedFees = savedFees;
    } else {
      let taxConvert = { taxes: {} };
      for (const key in fees) {
        if (Object.hasOwnProperty.call(fees, key)) {
          const taxContent = fees[key];
          if (
            taxContent.type === "percentual" &&
            key !== "_fintxdefaultpdv" &&
            key !== "_fintxminpdvprodutor"
          ) {
            taxConvert = {
              ...taxConvert,
              taxes: {
                ...taxConvert.taxes,
                [key]: {
                  ...fees[key],
                  value: Math.round(
                    parseFloat(fees[key].value).toFixed(2) * 100,
                    1
                  ),
                },
              },
            };
          } else {
            taxConvert = {
              ...taxConvert,
              taxes: {
                ...taxConvert.taxes,
                [key]: {
                  ...fees[key],
                },
              },
            };
          }
        }
      }
      selectedFees = taxConvert.taxes;
    }
    const selectedPaymentPlanId = paymentPlanId || eventPaymentPlanId;

    const validContact = "Preencha Contato de suporte ao cliente válido";
    let tagsstring = "";

    tags.forEach((t) => {
      tagsstring += t + ",";
    });

    tagsstring = tagsstring.substring(0, tagsstring.length - 1);

    let typePayments = [
      ...onlineTypes.map((item) => ({ id: item, mode: "online" })),
      ...posTypes.map((item) => ({ id: item, mode: "pos" })),
    ];

    if (startDate > endDate) {
      document.getElementById("loading").classList.add("esconde");
      Swal.fire({
        title: "Ops!",
        icon: "warning",
        text: "A data de inicio do evento precisa ser menor que a data do término!",
        confirmButtonText: "Ok",
      });
      return;
    }
    if (!event.organization_id) {
      document.getElementById("loading").classList.add("esconde");
      Swal.fire({
        title: "Ops!",
        icon: "warning",
        text: "Favor escolher uma organização!",
        confirmButtonText: "Ok",
      });
      return;
    }

    if (!communicationChannelType) {
      document.getElementById("loading").classList.add("esconde");
      Swal.fire({
        title: "Ops!",
        icon: "warning",
        text: "Favor selecionar o tipo de contato!",
        confirmButtonText: "Ok",
      });
      return;
    }
    if (isOnline && !ValidationsHelper.isWebLinkValid(eventLink)) {
      document.getElementById("loading").classList.add("esconde");
      Swal.fire({
        title: "Ops!",
        icon: "warning",
        text: "Favor informar o link de transmissão do evento!",
        confirmButtonText: "Ok",
      });
      return;
    }
    if (!communicationChannel) {
      document.getElementById("loading").classList.add("esconde");
      Swal.fire({
        title: "Ops!",
        icon: "warning",
        text: "Preencha Contato de suporte ao cliente",
        confirmButtonText: "Ok",
      });
      return;
    }
    if (
      communicationChannelType === "telephone" &&
      !isValidPhoneNumber(communicationChannel)
    ) {
      Swal.fire({
        title: "Ops!",
        icon: "warning",
        text: validContact,
        confirmButtonText: "Ok",
      });
      return;
    }
    if (
      communicationChannelType === "email" &&
      !ValidationsHelper.isEmailValid(communicationChannel)
    ) {
      Swal.fire({
        title: "Ops!",
        icon: "warning",
        text: validContact,
        confirmButtonText: "Ok",
      });
      return;
    }
    if (
      communicationChannelType === "social_media" &&
      !ValidationsHelper.isWebLinkValid(communicationChannel)
    ) {
      Swal.fire({
        title: "Ops!",
        icon: "warning",
        text: validContact,
        confirmButtonText: "Ok",
      });
      return;
    }

    let eventoatualizado = {
      id: event.id,
      organization_id: event.organization_id,
      name: nome,
      start_date: startDate,
      end_date: endDate,
      slug: slug,
      is_online: isOnline,
      is_public: isPublic,
      taxes: taxes,
      age_range: ageRange,
      tags: tagsstring,
      description: description,
      banner: banner,
      thumb: thumb,
      ticket_nomenclature: ticketNomenclature,
      ab_max_installments: ab_max_installments,
      ab_interest_rate: (ab_interest_rate || 0) * 100,
      max_online_order_tickets: max_online_order_tickets,
      max_pos_order_tickets: max_pos_order_tickets,
      sails_limit: sailsLimit
        ? moment(sailsLimit).format("YYYY-MM-DD HH:mm:ss")
        : null,
      expiration_minutes: expirationMinutes,
      cart_recovery_minutes: cartRecoveryMinutes,
      antifraud_type: antifraudType,
      fraud_risk_level: antifraudRiskLevel,
      max_sensible_methods_attempts: maxSensibleMethodsAttempts,
      force_threeds: forceThreeds ? 1 : 0,
      hours_to_enable_threeds: hoursToEnableThreeds,
      max_online_installments: maxOnlineInstallments,
      payments_plan_id: selectedPaymentPlanId,
      group_tickets_into_sectors: groupTicketsIntoSectors,
      link_other: eventLink,
      communication_channel: communicationChannel,
      communication_channel_type: communicationChannelType,
      currency,
      timezone,
      enable_attendees_checkout_autofill: enableAttendeesCheckoutAutofill,
    };

    Object.assign(event, eventoatualizado);
    let { categories, organizations, ...eventBody } = event;

    if (
      (categories[0]?.id !== 0 && categories[0]?.subCategories?.length === 0) ||
      (categories[1]?.id !== 0 && categories[1]?.subCategories?.length === 0)
    ) {
      Swal.fire({
        title: "Ops!",
        icon: "warning",
        text: "Preencha ao menos uma subcategoria.",
        confirmButtonText: "Ok",
      });
      return;
    }

    if (categories.length === 0) {
      Swal.fire({
        title: "Ops!",
        icon: "warning",
        text: "Preencha ao menos uma categoria.",
        confirmButtonText: "Ok",
      });
      return;
    }

    if (
      categories[0]?.subCategories?.length > 3 ||
      categories[1]?.subCategories?.length > 3
    ) {
      Swal.fire({
        title: "Ops!",
        icon: "warning",
        text: "Limite de 3 subcategorias.",
        confirmButtonText: "Ok",
      });
      return;
    }

    if (categories[0].position) {
      if (
        (categories[1].mainCategory.value !== 0 &&
          categories[1].sub.length === 0) ||
        (categories[0].mainCategory.value !== 0 &&
          categories[0].sub.length === 0)
      ) {
        Swal.fire({
          title: "Ops!",
          icon: "warning",
          text: "Preencha ao menos uma subcategoria",
          confirmButtonText: "Ok",
        });
        return;
      }

      if (categories[0].sub.length > 3 || categories[1].sub.length > 3) {
        Swal.fire({
          title: "Ops!",
          icon: "warning",
          text: "Limite de 3 subcategorias.",
          confirmButtonText: "Ok",
        });
        return;
      }

      categories = categories.map((category) => {
        return {
          id: category.mainCategory.value,
          name: category.mainCategory.label,
          subCategories: category.sub.map((el) => {
            const id = typeof el === "number" ? el : el.value;
            return {
              id,
            };
          }),
        };
      });
    }
    delete eventBody["type_payments"];
    delete eventBody["locations"];
    delete eventBody["sponsors"];
    delete eventBody["contract"];
    delete eventBody["taxes_list"];
    delete eventBody["orders_quantity"];

    document.getElementById("loading").classList.remove("esconde");

    if (event.orders_quantity > 0) {
      delete eventBody["currency"];
    }

    const body = {
      event: eventBody,
      categories,
      organizations,
      type_payments: typePayments,
      location: locations.location,
      taxes: selectedFees,
      contract: {
        id: event.contract.id,
        version: event.contract.version,
        identificador: event.contract.identificador,
      },
    };

    api
      .post("event/update", body)
      .then(() => {
        document.getElementById("loading").classList.add("esconde");
        Swal.fire(
          t("global:success"),
          t("events:eventEdit.updatedEventSuccessText"),
          "success"
        ).then(() => {
          window.location.href = `/tickets/dashboard?event_id=${eventid}`;
        });
      })
      .catch((error) => {
        document.getElementById("loading").classList.add("esconde");

        if (error.response.data.type === "INVALID_EXPIRATION_MINUTES") {
          Swal.fire({
            title: "Atenção!",
            icon: "error",
            text: t("events:eventEdit.salesSettings.errors.minimumTime"),
            confirmButtonText: "Ok",
          });
        } else if (error.response.data.type === "INVALID_USER") {
          Swal.fire({
            title: t("global:errors.ops"),
            icon: "error",
            text: t("global:errors.userPermissionDenied"),
            confirmButtonText: "Ok",
          }).then(() => {
            window.location.pathname = "/events";
          });
        } else if (error.response.data.type === "UNIQUE_CONSTRAINT_ERROR") {
          Swal.fire({
            title: t("global:errors.ops"),
            icon: "error",
            text: t("global:errors.internalServerError"),
            confirmButtonText: "Ok",
          });
        } else {
          Swal.fire({
            title: t("global:errors.ops"),
            icon: "error",
            text: t("global:errors.eventUniqueConstraint"),
            confirmButtonText: "Ok",
          });
        }
      });
  };

  useEffect(() => {
    if (isOnline === 1) {
      setLocation({
        ...locations,
        location: {
          name: "",
          formatted_address: "",
          number: "",
          complement: "",
          street: "",
          lat: "",
          lng: "",
          state: "",
          city: "",
          neighborhood: "",
        },
      });
    } else {
      const urlParams = new URLSearchParams(window.location.search);
      const eventid = urlParams.get("event_id");
      api
        .get(`event/details/${eventid}`)
        .then((res) => {
          let evento = res.data;
          setLocation({
            location: {
              id: evento.locations[0].id,
              name: evento.locations[0].name,
              formatted_address: evento.locations[0].formatted_address,
              number: evento.locations[0].number,
              complement: evento.locations[0].complement,
              street: evento.locations[0].street,
              neighborhood: evento.locations[0].neighborhood,
              city: evento.locations[0].city,
              state: evento.locations[0].state,
              zip_code: evento.locations[0].zip_code,
            },
          });
        })
        .catch(() => {
          Swal.fire({
            title: t("global:errors.ops"),
            icon: "error",
            text: t("global:errors.internalServerError"),
            confirmButtonText: "Ok",
          });
        });
    }
    // eslint-disable-next-line
  }, [isOnline]);

  const slugify = (text, ampersand = "e") => {
    const a = "àáäâãèéëêìíïîòóöôùúüûñçßÿỳýœæŕśńṕẃǵǹḿǘẍźḧ";
    const b = "aaaaaeeeeiiiioooouuuuncsyyyoarsnpwgnmuxzh";
    const p = new RegExp(a.split("").join("|"), "g");

    text = text
      .toString()
      .toLowerCase()
      .replace(/[\s_]+/g, "-")
      .replace(p, (c) => b.charAt(a.indexOf(c)))
      .replace(/&/g, `-${ampersand}-`)
      .replace(/[^\w-]+/g, "")
      .replace(/--+/g, "-")
      .replace(/^-+|-+$/g, "");

    setSlug(text);
  };

  const nameChange = (nome) => {
    setNome(nome);
    slugify(nome.trim());
  };

  const handleSponsorEdit = (sponsor) => {
    setShowSponsorModal("modal-show");
    setSponsor(sponsor);
  };

  const handleSponsorDelete = (id, name) => {
    Swal.fire({
      icon: "warning",
      title: "Atenção",
      text: `Você deseja remover ${name} da lista de parceiros?`,
      showCancelButton: true,
      cancelButtonText: "Não",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#5B4299",
      confirmButtonText: "Excluir",
      customClass: {
        confirmButton: "custom-confirm-button-outline",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        document.getElementById("loading").classList.remove("esconde");

        New_api.delete(`/event-sponsors/${id}`)
          .then(() => {
            Swal.fire({
              title: "Sucesso",
              icon: "success",
              text: `O parceiro ${name} foi removido com sucesso.`,
              confirmButtonText: "Ok",
              confirmButtonColor: "#5B4299",
            });

            loadSponsors(event.id);
          })
          .catch((err) => {
            Swal.fire({
              title: "Não foi possível remover o parceiro. Tente novamente.",
              icon: "error",
              confirmButtonColor: "#5B4299",
              confirmButtonText: "Ok",
            });
          })
          .finally(() => {
            document.getElementById("loading").classList.add("esconde");
          });
      }
    });
  };

  // Listener para adição de tags
  const pushTag = (e) => {
    e.preventDefault();
    if (e.key === "Enter") {
      let currentTag = e.target.value.trim().toLowerCase();
      let index = tags.indexOf(currentTag);
      // Caso a tag seja repetida acende a tag já existente por 2seg
      if (index > -1) {
        let tag = document.getElementById(`tag-${index}`);
        tag.className = "event-tag mr-3 mb-3 bg-red";
        setTimeout(() => {
          tag.className = "event-tag mr-3 mb-3";
        }, 2000);
      } else {
        // Caso contrário adiciona à lista de tags e reseta o input
        let t = tags;
        t.push(currentTag);
        setTags([...t]);
        e.target.value = "";
      }
    }
  };

  const loadCollaborators = (eventId) => {
    setLoadingCollaborators(true);

    api
      .get(`collaborators`, { params: { event_id: eventId } })
      .then((res) => setCollaborators(res.data))
      .catch(() => {
        Swal.fire({
          title: t("global:errors.ops"),
          icon: "error",
          text: t("global:errors.internalServerError"),
          confirmButtonText: "Ok",
        });
      })
      .finally(() => setLoadingCollaborators(false));
  };

  const handleCollaboratorSuccess = () => {
    Swal.fire({
      title: "Sucesso",
      icon: "success",
      text: "Colaborador atrelado com sucesso!",
      confirmButtonText: "Ok",
    });

    setShowCollaboratorModal(false);
    loadCollaborators(event.id);
  };

  const handleCollaboratorError = (error) => {
    Swal.fire({
      title: "Erro",
      icon: "error",
      text:
        error.message || "Ocorreu um erro ao adicionar o colaborador ao evento",
      confirmButtonText: "Ok",
    });

    setShowCollaboratorModal(false);
  };

  const handleRemoveCollaborator = (collaboratorId) => {
    Swal.fire({
      title: "Deletar",
      icon: "warning",
      text: "Deseja realmente remover este colaborador?",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#95aac9",
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
    }).then((result) => {
      if (result.value) {
        api
          .delete(`collaborators/${collaboratorId}`)
          .then(() => {
            Swal.fire({
              title: "Sucesso",
              icon: "success",
              text: "Colaborador removido com sucesso.",
              confirmButtonText: "Ok",
            });

            loadCollaborators(event.id);
          })
          .catch(() => {
            Swal.fire({
              title: "Erro",
              icon: "error",
              text: "Ocorreu um erro ao remover o colaborador do evento.",
              confirmButtonText: "Ok",
            });
          });
      }
    });
  };

  useEffect(() => {
    changeState(changeCard);
  }, [changeCard]);

  const organizationSelected = (organization_id, timezone, currency) => {
    if (organization_id) {
      event.organization_id = organization_id;
    }
    if (currency && timezone && event?.orders_quantity > 0) {
      setCurrency(currency);
      setTimezone(timezone);
    }
  };

  const handleEndDate = (date) => {
    setEndDate(date);

    if (sailsLimit > date) {
      setLimit(date);
    }
  };

  const updateCommunicationChannelState = (value = "") => {
    setCommunicationChannel(value);
  };

  const updateCommunicationChannelTypeState = (event) => {
    setCommunicationChannelType(event.value);
    setCommunicationChannel("");
  };

  const updateTypeEvent = () => {
    if (isOnline === 1) {
      setIsOnline(0);
    } else {
      setIsOnline(1);
    }

    setEventLink("");
  };

  const navigationTabs = (positionNumber) => {
    let baseUrl = `/tickets/new-event-edit`;
    let path;

    switch (positionNumber) {
      case 3:
        path = `${baseUrl}/salesConfig/evento`;
        break;
      case 4:
        path = `${baseUrl}/collaborators/evento`;
        break;
      default:
        path = `${baseUrl}/evento`;
        break;
    }

    history.push(`${path}?event_id=${eventid}&position=${positionNumber}`);
    setChange(positionNumber);
  };

  const handleTimezoneAndCurrency = (name, { value }) => {
    if (name === "currency") {
      if (value !== currency) {
        Swal.fire({
          title:
            "Após a primeira venda, você não poderá alterar a moeda de venda",
          text: "Deseja confirmar a alteração?",
          confirmButtonText: "Alterar moeda",
          cancelButtonText: "Voltar",
          confirmButtonColor: "#0050C3",
          icon: "warning",
          reverseButtons: true,
          showCancelButton: true,
          customClass: {
            cancelButton: "back-currency-class",
          },
        }).then((result) => {
          if (result.value) {
            setCurrency(value);
          }
        });
      }
    } else {
      setTimezone(value);
    }
  };

  return (
    <Fragment>
      <PageHeader
        title={t("events:eventEdit.header.title")}
        text={t("events:eventEdit.header.subtitle")}
      />
      <Loading title={t("global:loadingText")} />
      <div className="container-fluid" style={{ paddingBottom: "30px" }}>
        <div className="col mt-1" id="subheader">
          <ul className="nav nav-tabs nav-overflow header-tabs">
            <li className="nav-item">
              <span
                className="nav-link text-nowrap subheader-item"
                onClick={() => navigationTabs(0)}
              >
                {t("events:eventEdit.tabs.eventInformations")}
              </span>
            </li>
            <li className="nav-item">
              <span
                className="nav-link text-nowrap subheader-item"
                onClick={() => navigationTabs(1)}
              >
                {t("events:eventEdit.tabs.dateAndTime")}
              </span>
            </li>
            <li className="nav-item">
              <span
                className="nav-link text-nowrap subheader-item"
                onClick={() => navigationTabs(2)}
              >
                {t("events:eventEdit.tabs.location")}
              </span>
            </li>
            <li className="nav-item">
              <span
                className="nav-link text-nowrap subheader-item"
                onClick={() => navigationTabs(3)}
              >
                {t("events:eventEdit.tabs.salesSettings")}
              </span>
            </li>
            <li className="nav-item">
              <span
                className="nav-link text-nowrap subheader-item"
                onClick={() => navigationTabs(4)}
              >
                {t("events:eventEdit.tabs.collaborators")}
              </span>
            </li>
            <li className="nav-item">
              <span
                className="nav-link text-nowrap subheader-item"
                onClick={() => navigationTabs(5)}
              >
                {t("events:eventEdit.tabs.partners")}
              </span>
            </li>
            <li className="nav-item">
              <span
                className="nav-link text-nowrap subheader-item"
                onClick={() => navigationTabs(6)}
              >
                {t("events:eventEdit.tabs.taxes")}
              </span>
            </li>
          </ul>
        </div>

        {changeCard === 0 ? (
          <div className="container-fluid mt-2">
            <div className="card">
              <div className="card-body">
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label className="form-label">Nome do Evento</label>
                    <input
                      className="form-control inputs"
                      name="name"
                      placeholder="Nome do evento"
                      value={nome}
                      onChange={(e) => nameChange(e.target.value)}
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label className="form-label">Slug</label>
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span
                          className="input-group-text inputs text-small"
                          id="inputTags"
                        >
                          {linkEmpresa}/eventos/
                        </span>
                        <span />
                      </div>
                      <input
                        className="form-control inputs"
                        name="slug"
                        value={slug || ""}
                        onChange={(e) => {
                          setSlug(e.target.value);
                        }}
                        onBlur={(e) => {
                          slugify(e.target.value);
                        }}
                        required
                        placeholder="Slug do evento (link)"
                      />
                    </div>
                  </div>
                </div>

                <div className="form-row">
                  <div className="form-group col-md-6 col-12">
                    <label className="form-label">Organização</label>
                    <OrganizationsInput
                      defaultValue={organizationName}
                      callBack={organizationSelected}
                    />
                  </div>

                  <div className="form-group col-md-6 col-12 pl-2 pr-2">
                    <label className="form-label">
                      Nomeclatura dos Bilhetes
                    </label>
                    <select
                      className="custom-select"
                      name="ticket_nomenclature"
                      onChange={(e) => {
                        setTicketNomenclature(e.target.value);
                      }}
                      value={ticketNomenclature}
                      defaultValue={ticketNomenclature}
                    >
                      <option value="Ingressos">Ingressos</option>
                      <option value="Inscrições">Inscrições</option>
                      <option value="Doações">Doações</option>
                      <option value="Couverts">Couverts</option>
                    </select>
                  </div>
                </div>

                <div className="form-row">
                  <div className="form-group col-md-6 col-12 pl-2">
                    <label className="form-label">Faixa etária</label>
                    <input
                      className="form-control"
                      name="age_range"
                      onChange={(e) => {
                        let { value } = e.target;
                        if (value < 0) {
                          value = 0;
                        } else if (value > 100) {
                          value = 100;
                        }
                        setAgeRange(value);
                      }}
                      value={ageRange || ""}
                      type="number"
                    />
                  </div>
                  <div className="form-group col-md-6 col-12 pl-2">
                    <label className="form-label">Moeda</label>
                    <InputGroup>
                      <InputGroup.Prepend>
                        <InputGroup.Text>
                          <DollarIcon />
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <Select
                        styles={switchStyles2}
                        isSearchable={false}
                        isDisabled={event?.orders_quantity > 0}
                        className="col p-0"
                        value={
                          currency
                            ? currenciesOptions.filter(
                                (option) => option.value === currency
                              )
                            : ""
                        }
                        onChange={(value) =>
                          handleTimezoneAndCurrency("currency", value)
                        }
                        options={currenciesOptions}
                        components={{
                          IndicatorSeparator: () => null,
                        }}
                      />
                    </InputGroup>
                    {event?.orders_quantity > 0 && (
                      <span className="first-sale-info">
                        <ExclamationMark className="mr-1" />
                        Não é possível alterar após vendas terem sido
                        realizadas.
                      </span>
                    )}
                  </div>
                </div>

                <div className="form-row mt-3">
                  <div className="col-md-12 mb-3">
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span
                          className="input-group-text inputs"
                          id="inputTags"
                        >
                          Tags
                        </span>
                      </div>
                      <input
                        type="text"
                        placeholder="Digite a tag e pressione enter para adicionar."
                        className="form-control inputs"
                        id="tags"
                        onKeyUp={pushTag}
                        required
                      />
                    </div>
                  </div>
                </div>

                <div className="form-row">
                  {tags.map((tag, i) => (
                    <span
                      id={`tag-${i}`}
                      key={`tag-${i}`}
                      className="event-tag mr-3 mb-3 c3"
                    >
                      {tag}{" "}
                      <FontAwesomeIcon
                        className="ml-2"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          let t = tags;
                          t = t.filter(function (item) {
                            return item !== tag;
                          });
                          setTags(t);
                        }}
                        icon={faTimesCircle}
                      />
                    </span>
                  ))}
                </div>
                <b>Contato de suporte ao cliente</b>
                <br />
                <span className="subtitle-contact">
                  Este contato é para quem comprou o ingresso e precisa de
                  suporte em relação ao evento.
                </span>
                <hr />
                <div className="row pb-4">
                  <div className="col-md-8 col-12 form-row">
                    <div className="col-md-6 col-12">
                      <label className="form-label">Tipo de contato</label>
                      <Select
                        name="communication_channel_type"
                        styles={channelSelectStyles}
                        className="col-12 p-0"
                        value={
                          communicationChannelType
                            ? channels.filter(
                                (option) =>
                                  option.value === communicationChannelType
                              )
                            : ""
                        }
                        onChange={updateCommunicationChannelTypeState}
                        options={channels}
                        components={{
                          IndicatorSeparator: () => null,
                        }}
                      />
                    </div>
                    <div className="col-md-6 col-12 pt-2">
                      <label className="form-label"></label>
                      {communicationChannelType === "telephone" ? (
                        <PhoneNumber
                          defaultCountry="BR"
                          initialValueFormat="national"
                          placeholder="Número do telefone"
                          value={communicationChannel}
                          onChange={updateCommunicationChannelState}
                        />
                      ) : (
                        <input
                          className="form-control inputs"
                          maxLength={50}
                          name="communication_channel"
                          placeholder={UtilsHelper.typeChannelPlaceholderSelect(
                            communicationChannelType
                          )}
                          disabled={!communicationChannelType}
                          value={communicationChannel}
                          onChange={(e) =>
                            updateCommunicationChannelState(e.target.value)
                          }
                        />
                      )}
                    </div>
                  </div>
                </div>

                <Fragment>
                  <b>Categorize o seu evento</b>
                  <hr />
                  <EditCategorySelector
                    categories={categories}
                    callback={editCategoryCallback}
                  />
                </Fragment>

                <div className="form-row mt-3">
                  <div className="col-md-6">
                    <div className="card card-img-upload">
                      <div className="card-body row">
                        <div className="col-md-8">
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span className="input-group-text">
                                <FontAwesomeIcon icon={faImage} /> Banner
                              </span>
                            </div>
                            <div className="custom-file">
                              <input
                                type="file"
                                accept="image/png, image/jpeg"
                                className="custom-file-input"
                                id="event-banner"
                                disabled={bannerloading}
                                onChange={(e) => {
                                  uploadImage(e, "event-banner");
                                }}
                                aria-describedby="inputGroupFileAddon01"
                              ></input>
                              <label
                                className="custom-file-label"
                                id="banner-label"
                                htmlFor="event-banner"
                              >
                                <div
                                  hidden={!bannerloading}
                                  className="spinner-border spinner-border-sm text-primary"
                                  role="status"
                                >
                                  <span className="sr-only">Loading...</span>
                                </div>
                                {!bannerloading && !banner
                                  ? "Procurar..."
                                  : null}
                              </label>
                            </div>
                          </div>
                          <span className="upload-desc text-small">
                            A dimensão recomendada é de <b>800 x 350</b>
                          </span>
                        </div>
                        <div className="col-md-4">
                          {banner && (
                            <div
                              className="banner-uploaded"
                              style={{
                                backgroundImage: `url(${remoteBanner})`,
                              }}
                            ></div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="card card-img-upload">
                      <div className="card-body row">
                        <div className="col-md-8">
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span className="input-group-text">
                                <FontAwesomeIcon icon={faImage} /> Miniatura
                              </span>
                            </div>
                            <div className="custom-file">
                              <input
                                type="file"
                                accept="image/png, image/jpeg"
                                className="custom-file-input"
                                id="event-thumbnail"
                                disabled={thumbloading}
                                onChange={(e) => {
                                  uploadImage(e, "event-thumbnail");
                                }}
                                aria-describedby="inputGroupFileAddon02"
                              ></input>
                              <label
                                className="custom-file-label"
                                id="thumb-label"
                                htmlFor="event-thumbnail"
                              >
                                <div
                                  hidden={!thumbloading}
                                  className="spinner-border spinner-border-sm text-primary"
                                  role="status"
                                >
                                  <span className="sr-only">Loading...</span>
                                </div>
                                {!thumbloading && !thumb ? "Procurar..." : null}
                              </label>
                            </div>
                          </div>
                          <span className="upload-desc text-small">
                            A dimensão recomendada é de <b>300 x 230</b>
                          </span>
                        </div>
                        <div className="col-md-4">
                          {thumb && (
                            <div
                              className="img-uploaded"
                              style={{
                                backgroundImage: `url(${remoteThumbnail})`,
                              }}
                            ></div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-12">
                  <b>Detalhes do evento</b>
                  <hr />
                  <JoditEditor
                    defaultValue={description}
                    value={description}
                    config={config}
                    onBlur={(newContent) => {}}
                    onChange={(newContent) => {
                      setDescription(newContent);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        ) : changeCard === 1 ? (
          <div className="container-fluid mt-4">
            <div className="card">
              <div className="card-body">
                <div className="form-row">
                  <div className="form-group col-md-3 col-12 pl-2">
                    <label className="form-label">Fuso horário</label>
                    <InputGroup>
                      <InputGroup.Prepend>
                        <InputGroup.Text>
                          <TimezoneIcon />
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <Select
                        styles={switchStyles2}
                        className="col p-0"
                        value={
                          timezone
                            ? timezoneOptions.filter(
                                (option) => option.value === timezone
                              )
                            : ""
                        }
                        onChange={(value) =>
                          handleTimezoneAndCurrency("timezone", value)
                        }
                        options={timezoneOptions}
                        components={{
                          IndicatorSeparator: () => null,
                        }}
                      />
                    </InputGroup>
                  </div>
                  <div className="col-md-3 mb-3">
                    <DatePicker
                      leftIcon
                      label={t("events:editEvent.startDate")}
                      value={startDate}
                      showTimeSelect
                      onChange={(value) => {
                        setStartDate(value);
                      }}
                      minDate={new Date()}
                      maxDate={endDate || null}
                      minTime={
                        isSameDay(startDate, new Date())
                          ? new Date()
                          : startOfDay(new Date())
                      }
                      maxTime={endOfDay(new Date())}
                      placeholderText={t("components:datepicker.startDate")}
                    />
                  </div>
                  <div className="col-md-3 mb-3">
                    <DatePicker
                      leftIcon
                      label={t("events:editEvent.endDate")}
                      value={endDate}
                      showTimeSelect
                      onChange={handleEndDate}
                      minDate={startDate ? new Date(startDate) : new Date()}
                      minTime={
                        isSameDay(startDate, endDate)
                          ? startDate
                          : startOfDay(new Date())
                      }
                      maxTime={endOfDay(new Date())}
                      placeholderText={t("components:datepicker.endDate")}
                    />
                  </div>
                  <div className="col-md-3">
                    <DatePicker
                      leftIcon
                      label={t("events:editEvent.limitDate")}
                      value={sailsLimit}
                      showTimeSelect
                      onChange={(value) => {
                        setLimit(value);
                      }}
                      minDate={startDate}
                      maxDate={endDate}
                      minTime={
                        isSameDay(sailsLimit, startDate)
                          ? startDate
                          : startOfDay(new Date())
                      }
                      maxTime={
                        isSameDay(sailsLimit, endDate)
                          ? endDate
                          : endOfDay(new Date())
                      }
                      placeholderText={t(
                        "events:editEvent.limitDatePlaceholder"
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : changeCard === 2 ? (
          <div className="container-fluid mt-4">
            <div className="card md-4">
              <div className="card-body">
                <GroupHeader
                  description={t(
                    "events:createEvent:eventModalityDescription2"
                  )}
                  dclass="d d-txt"
                />
                <div className="form-row col options-container">
                  <div className="form-group form-check form-check-inline">
                    <div class="form-check form-check-inline">
                      <input
                        onClick={() => updateTypeEvent("local")}
                        checked={!isOnline}
                        class="form-check-input"
                        type="radio"
                        name="inlineRadioOptions"
                        id="inlineRadio1"
                      ></input>
                      <label class="form-check-label" htmlFor="inlineRadio1">
                        {t("events:createEvent:inPerson")}
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        onClick={() => updateTypeEvent("online")}
                        checked={isOnline}
                        class="form-check-input"
                        type="radio"
                        name="inlineRadioOptions"
                        id="inlineRadio2"
                      ></input>
                      <label class="form-check-label" htmlFor="inlineRadio2">
                        Online
                      </label>
                    </div>
                  </div>
                </div>
                <hr />
                {isOnline ? (
                  <div className="form-row">
                    <div className="form-group">
                      <label className="form-label">Link de transmissão</label>
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span
                            className="input-group-text inputs"
                            id="inputTags"
                          >
                            <FontAwesomeIcon icon={faGlobe} />
                          </span>
                          <span />
                        </div>
                        <input
                          className="form-control inputs"
                          name="eventLink"
                          value={eventLink || ""}
                          onChange={(e) => {
                            setEventLink(e.target.value);
                          }}
                          required
                          placeholder="https://www.exemplo.com"
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <EventAddress
                    stepData={locations}
                    setStepData={setLocation}
                  />
                )}
              </div>
            </div>
          </div>
        ) : changeCard === 3 ? (
          <div className="card mt-4">
            <div className="card-body">
              <div className="form-row justify-content-between">
                <div className="form-group col-md-6">
                  <label className="form-label">
                    {t("events:eventEdit.salesSettings.taxesPolicies.label")}
                  </label>
                  <div className="row">
                    <div className="col-md-5 mt-2">
                      <SuperRadioButton
                        value={taxes}
                        requiredValue="Repassar"
                        label={t(
                          "events:eventEdit.salesSettings.taxesPolicies.passOnText"
                        )}
                        space={true}
                        callback={(value) => setTaxes(value)}
                      />
                    </div>
                    <div className="col-md-6 mt-2">
                      <SuperRadioButton
                        value={taxes}
                        requiredValue="Absorver"
                        label={t(
                          "events:eventEdit.salesSettings.taxesPolicies.absorbText"
                        )}
                        space={true}
                        callback={(value) => setTaxes(value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group col-md-6">
                  <label className="form-label">
                    {t("events:eventEdit.salesSettings.visibility.label")}
                  </label>
                  <div className="row">
                    <div className="col-md-6 mt-2">
                      <SuperRadioButton
                        value={isPublic}
                        requiredValue={1}
                        label={t(
                          "events:eventEdit.salesSettings.visibility.publicText"
                        )}
                        space={true}
                        callback={(value) => setIsPublic(value)}
                      />
                    </div>
                    <div className="col-md-6 mt-2">
                      <SuperRadioButton
                        value={isPublic}
                        requiredValue={0}
                        label={t(
                          "events:eventEdit.salesSettings.visibility.privateText"
                        )}
                        space={true}
                        callback={(value) => setIsPublic(value)}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-12 col-md-6">
                  <div className="row">
                    <div className="col-md-12">
                      <label className="form-label mb-2">
                        {t(
                          "events:eventEdit.salesSettings.ticketsGroupedBySectorsLabel"
                        )}
                      </label>
                      <div>
                        <Switch
                          handleDiameter={16}
                          onColor="#75d1f2"
                          onHandleColor={COLORS.middleBlue}
                          offColor={COLORS.weakGray}
                          offHandleColor={COLORS.gray}
                          height={12}
                          width={28}
                          onChange={() =>
                            setGroupTicketsIntoSectors((prev) => !prev)
                          }
                          checked={groupTicketsIntoSectors}
                          uncheckedIcon={false}
                          checkedIcon={false}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="form-group col-12 col-md-6">
                  <div className="row">
                    <div className="col-md-12">
                      <label className="form-label mb-2">
                        {t(
                          "events:eventEdit.salesSettings.showCheckoutAttendeeAutofill"
                        )}
                        <DataTip
                          tipContent={t(
                            "events:eventEdit.salesSettings.showCheckoutAttendeeAutofillHelpText"
                          )}
                        />
                      </label>
                      <div>
                        <Switch
                          handleDiameter={16}
                          onColor="#75d1f2"
                          onHandleColor={COLORS.middleBlue}
                          offColor={COLORS.weakGray}
                          offHandleColor={COLORS.gray}
                          height={12}
                          width={28}
                          onChange={() =>
                            setEnableAttendeesCheckoutAutofill((prev) => !prev)
                          }
                          checked={enableAttendeesCheckoutAutofill}
                          uncheckedIcon={false}
                          checkedIcon={false}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {mostraUSer && (
                <Fragment>
                  <hr />
                  <div className="form-row">
                    <div className="col-md-12 mb-3">
                      <label className="form-label mt-3 mb-2">
                        {t(
                          "events:eventEdit.salesSettings.paymentsFormsLabel.onlineAndApp"
                        )}
                      </label>

                      <div className="custom-input custom-checkbox row">
                        {onlineTypePayments.map((item, i) => (
                          <div
                            key={item.id}
                            className="col-6 col-sm-4 col-lg-3 col-xl-2 mb-2"
                          >
                            <div
                              className={
                                onlineTypes.includes(item.id)
                                  ? "btnClass active"
                                  : "btnClass"
                              }
                              onClick={() =>
                                handlePaymentType(item.id, "online")
                              }
                            >
                              <PaymentButtons
                                method={item.raw_method}
                                name={item.name}
                              />
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="col-md-4 mb-2">
                      <label className="form-label mb-2">
                        {t(
                          "events:eventEdit.salesSettings.maxTicketPerSaleLabel.onlineAndApp"
                        )}
                      </label>
                      <input
                        min={1}
                        value={max_online_order_tickets}
                        className="form-control"
                        onChange={(e) => {
                          set_max_online_order_tickets(
                            numberMask(e.target.value)
                          );
                        }}
                      />
                    </div>
                    <div className="col-md-4 mb-2">
                      <label className="form-label mb-2">
                        {t("events:eventEdit.salesSettings.saleLimitLabel")}
                      </label>
                      <input
                        min={5}
                        value={expirationMinutes}
                        className="form-control"
                        onChange={(e) => {
                          setExpirationMinutes(numberMask(e.target.value));
                        }}
                      />
                    </div>
                    <div className="col-md-4 mb-2">
                      <label className="form-label mb-2">
                        {t(
                          "events:eventEdit.salesSettings.cartRecoveryLimitLabel"
                        )}
                      </label>
                      <input
                        min={0}
                        value={cartRecoveryMinutes}
                        className="form-control"
                        onChange={(e) => {
                          setCartRecoveryMinutes(numberMask(e.target.value));
                        }}
                      />
                    </div>
                    <div className="col-md-4 mb-2">
                      <label className="form-label mb-2">
                        {t(
                          "events:eventEdit.salesSettings.maximumInstallmentLabel.onlineAndApp"
                        )}
                      </label>
                      <input
                        min={1}
                        max={12}
                        value={maxOnlineInstallments}
                        className="form-control"
                        onChange={(e) => {
                          setMaxOnlineInstallments(numberMask(e.target.value));
                        }}
                      />
                    </div>
                    <div className="col-md-4 mb-2">
                      <label className="form-label mb-2">
                        {t(
                          "events:eventEdit.salesSettings.paymentPlanSelectLabel"
                        )}
                      </label>
                      <select
                        className="form-control"
                        onChange={(e) => setEventPaymentPlanId(e.target.value)}
                      >
                        <option value={""}>
                          {" "}
                          {t(
                            "events:eventEdit.salesSettings.defaultPaymentPlan"
                          )}
                        </option>
                        {paymentPlans.map((paymentPlan, index) => (
                          <option
                            value={paymentPlan.id}
                            key={index}
                            selected={paymentPlan.id === eventPaymentPlanId}
                          >
                            {paymentPlan.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <hr />
                  <div className="form-row">
                    <div className="col-md-12 mb-3">
                      <label className="form-label mb-2">
                        {t(
                          "events:eventEdit.salesSettings.paymentsFormsLabel.pos"
                        )}
                      </label>

                      <div className="custom-input custom-checkbox row">
                        {posTypePayments.map((item, i) => (
                          <div
                            key={item.id}
                            className="col-6 col-sm-4 col-lg-3 col-xl-2 mb-2"
                          >
                            <div
                              className={
                                posTypes.includes(item.id)
                                  ? "btnClass active"
                                  : "btnClass"
                              }
                              onClick={() => handlePaymentType(item.id, "pos")}
                            >
                              <PaymentButtons
                                method={item.raw_method}
                                name={item.name}
                              />
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-4 mb-2">
                      <label className="form-label mb-2">
                        {t(
                          "events:eventEdit.salesSettings.maxTicketPerSaleLabel.pos"
                        )}
                      </label>
                      <input
                        min={1}
                        value={max_pos_order_tickets}
                        className="form-control"
                        onChange={(e) => {
                          set_max_pos_order_tickets(numberMask(e.target.value));
                        }}
                      />
                    </div>
                    <div className="col-md-4 mb-2">
                      <label className="form-label mb-2">
                        {t(
                          "events:eventEdit.salesSettings.maximumInstallmentLabel.pos"
                        )}
                      </label>
                      <NumberFormat
                        allowNegative={false}
                        value={ab_max_installments}
                        className="form-control"
                        onChange={(e) =>
                          setAb_max_installments(numberMask(e.target.value))
                        }
                      />
                    </div>
                    <div className="col-md-4 mb-2">
                      <label className="form-label mb-2">
                        {t("events:eventEdit.salesSettings.interestRateLabel")}
                      </label>
                      <NumberFormat
                        suffix="%"
                        decimalScale={1}
                        allowNegative={false}
                        fixedDecimalScale={true}
                        value={ab_interest_rate}
                        className="form-control"
                        onValueChange={({ floatValue }) =>
                          setab_interest_rate(floatValue)
                        }
                      />
                    </div>
                  </div>

                  <hr />
                  <div className="row mt-3">
                    <div className="col-md-4 mb-2">
                      <label className="form-label mb-2">
                        {t("events:eventEdit.salesSettings.riskLevel.label")}
                      </label>
                      <select
                        className="custom-select"
                        name="fraud_risk_level"
                        onChange={(e) => {
                          setAntifraudRiskLevel(e.target.value);
                        }}
                        value={antifraudRiskLevel}
                        defaultValue={antifraudRiskLevel}
                      >
                        <option value="low">
                          {t(
                            "events:eventEdit.salesSettings.riskLevel.options.low"
                          )}
                        </option>
                        <option value="medium">
                          {t(
                            "events:eventEdit.salesSettings.riskLevel.options.medium"
                          )}
                        </option>
                        <option value="high">
                          {t(
                            "events:eventEdit.salesSettings.riskLevel.options.high"
                          )}
                        </option>
                      </select>
                    </div>
                    <div className="col-md-4 mb-2">
                      <label className="form-label mb-2">
                        {t(
                          "events:eventEdit.salesSettings.eventCategory.label"
                        )}
                      </label>
                      <select
                        className="custom-select"
                        name="antifraud_type"
                        onChange={(e) => {
                          setAntifraudType(e.target.value);
                        }}
                        value={antifraudType}
                        defaultValue={antifraudType}
                      >
                        <option value="show">
                          {t(
                            "events:eventEdit.salesSettings.eventCategory.options.show"
                          )}
                        </option>
                        <option value="theater">
                          {t(
                            "events:eventEdit.salesSettings.eventCategory.options.theater"
                          )}
                        </option>
                        <option value="movies">
                          {t(
                            "events:eventEdit.salesSettings.eventCategory.options.movies"
                          )}
                        </option>
                        <option value="party">
                          {t(
                            "events:eventEdit.salesSettings.eventCategory.options.parties"
                          )}
                        </option>
                        <option value="festival">
                          {" "}
                          {t(
                            "events:eventEdit.salesSettings.eventCategory.options.festivals"
                          )}
                        </option>
                        <option value="course">
                          {t(
                            "events:eventEdit.salesSettings.eventCategory.options.courses"
                          )}
                        </option>
                        <option value="sports">
                          {t(
                            "events:eventEdit.salesSettings.eventCategory.options.sport"
                          )}
                        </option>
                        <option value="corporate">
                          {t(
                            "events:eventEdit.salesSettings.eventCategory.options.corporate"
                          )}
                        </option>
                      </select>
                    </div>
                    <div className="col-md-4 mb-2">
                      <label className="form-label mb-2">
                        {t(
                          "events:eventEdit.salesSettings.maximumAttemptsLabel"
                        )}
                      </label>
                      <input
                        min={1}
                        value={maxSensibleMethodsAttempts}
                        className="form-control"
                        onChange={(e) => {
                          setMaxSensibleMethodsAttempts(
                            numberMask(e.target.value)
                          );
                        }}
                      />
                    </div>
                    <div className="col-md-4 mb-2">
                      <label className="form-label mb-2">
                        {t(
                          "events:eventEdit.salesSettings.forceAutentificationLabel"
                        )}
                      </label>
                      <div>
                        <Switch
                          handleDiameter={16}
                          onColor="#75d1f2"
                          onHandleColor={COLORS.middleBlue}
                          offColor={COLORS.weakGray}
                          offHandleColor={COLORS.gray}
                          height={12}
                          width={28}
                          onChange={() => setForceThreeds((prev) => !prev)}
                          checked={forceThreeds}
                          uncheckedIcon={false}
                          checkedIcon={false}
                        />
                      </div>
                    </div>
                    <div className="col-md-4 mb-2">
                      <label className="form-label mb-2">
                        {t(
                          "events:eventEdit.salesSettings.activationPeriodLabel"
                        )}
                      </label>
                      <input
                        min={0}
                        value={hoursToEnableThreeds}
                        className="form-control"
                        readOnly={forceThreeds}
                        onChange={(e) => {
                          setHoursToEnableThreeds(numberMask(e.target.value));
                        }}
                      />
                    </div>
                  </div>
                </Fragment>
              )}
            </div>
          </div>
        ) : changeCard === 4 ? (
          <div className="container-fluid mt-4">
            <div className="card">
              <div className="card-body">
                <div className="form-row justify-content-end">
                  <div className="col-md-3">
                    <button
                      onClick={() => setShowCollaboratorModal(true)}
                      className="btn btn-outline-primary w-100"
                    >
                      Adicionar colaborador
                    </button>
                  </div>
                </div>
                <div className="form-row">
                  <div className="card-body">
                    {collaborators.length === 0 && !loadingCollaborators ? (
                      <div className="text-center">
                        <h6 className="mb-0 mt-2">
                          Nenhum colaborador foi adicionado.
                        </h6>
                      </div>
                    ) : (
                      <>
                        <div className="mt-2 mb-2 row justify-content-between">
                          <div className="col-6 col-md-5">
                            <h6>Nome</h6>
                          </div>
                          <div className="col-md-5 hidden-mobile">
                            <h6>Email</h6>
                          </div>
                          <div className="col-6 col-md-2 text-right">
                            <h6>Ações</h6>
                          </div>
                        </div>

                        {collaborators.map((collaborator) => (
                          <div
                            key={collaborator.id}
                            className="producer-row row"
                          >
                            <div className="col-lg-5 col-10 producer-info">
                              <b>
                                {collaborator.first_name +
                                  " " +
                                  collaborator.last_name}
                              </b>
                            </div>
                            <div className="col-5 hidden-mobile">
                              {collaborator.email}
                            </div>
                            <div className="col-2">
                              <div className="row d-flex justify-content-end mr-2">
                                <button
                                  className="btn-table"
                                  data-tip="Deletar colaborador"
                                  onClick={() =>
                                    handleRemoveCollaborator(collaborator.id)
                                  }
                                >
                                  <FontAwesomeIcon icon={faTrashAlt} />
                                </button>
                              </div>
                            </div>
                          </div>
                        ))}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : changeCard === 5 ? (
          <div className="container-fluid mt-4">
            <div className="card">
              <div className="card-body">
                <b>Adicionar parceiros</b>
                <hr />
                <div className="d-flex justify-content-end sponsor-count">
                  <p>{`${sponsors.length}/10 parceiros`}</p>
                </div>
                <div className="button-sponsors-container">
                  <ButtonDashed
                    onMouseOver={() => setStrokeColor("white")}
                    onMouseOut={() => setStrokeColor("#2075F4")}
                    SvgSrc={AddCircle}
                    fill="none"
                    stroke={strokeColor}
                    onClick={() => handleSponsorsModal("modal-show")}
                    text="Clique aqui para adicionar parceiros"
                  />

                  <div className="form-row">
                    <div className="card-body">
                      {sponsors.length === 0 ? (
                        <div className="text-center empty-msg">
                          <h3 className="mb-0 mt-2">Listagem vazia</h3>
                          <p>Ainda não foi adicionado nenhum apoiador.</p>
                        </div>
                      ) : (
                        <div className="d-flex flex-column sponsor-cards">
                          {sponsors.map((sponsor) => (
                            <SponsorCard
                              onClickEdit={handleSponsorEdit}
                              onClickTrash={handleSponsorDelete}
                              key={sponsor.id}
                              hasTrashCan={false}
                              sponsor={sponsor}
                            />
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <Advanced
            showSubmitButton={!userInformations.is_admin}
            readOnly={readOnlyTaxes}
            org_id={event.organization_id}
            userIsAdmin={userInformations.is_admin}
            callBackEdit={saveEvent}
            paymentPlans={paymentPlans}
            currency={event.currency}
            taxes={fees}
            defaultPaymentPlan={eventPaymentPlanId}
            isEvent
          />
        )}

        {!dontShowSaveBtn.includes(changeCard) && (
          <div className="box-button">
            <button
              className="btn btn-primary col-md-3 c3"
              style={{ width: "100%" }}
              type="submit"
              onClick={() => saveEvent()}
            >
              {t("global:Save")}
            </button>
          </div>
        )}
      </div>

      <CollaboratorModal
        show={showCollaboratorModal}
        eventId={event?.id}
        onClose={() => setShowCollaboratorModal(false)}
        onSuccess={handleCollaboratorSuccess}
        onError={handleCollaboratorError}
      />

      <SponsorsModal
        status={showSponsorModal}
        sponsorsList={sponsors}
        onClose={handleSponsorsModal}
        callback={handleSponsorModalForm}
        sponsor={sponsor}
      />
    </Fragment>
  );
};

export default EventEdit;
