//Common
import React, { useState, useEffect, useCallback } from "react";
import { ToastContainer } from "react-toastr";
import QRCode from "qrcode.react";
import Swal from "sweetalert2";
import { genderOptions } from "services/constants";
import "./styles.css";

//Providers
import Api from "services/api.js";
import { cpfMask, phoneMask, cepMask } from "mask.js";
import axios from "axios";

let container;
const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const ModalUsers = (props) => {
  const [register, setRegister] = useState({
    id: "",
    document: "",
    telephone: "",
    user_id: "",
    address_id: "",
    address: {
      id: "",
      zip_code: "",
      street: "",
      neighborhood: "",
      complement: "",
      state: "",
      city: "",
      number: "",
    },
    user: {
      id: "",
      email: "",
      first_name: "",
      last_name: "",
      gender: "",
      facebook: "",
      instagram: "",
      x_twitter: "",
      password: "",
      confirmpassword: "",
      qrcode: "",
    },
  });

  const updateUser = (e) => {
    setRegister({
      ...register,
      user: {
        ...register.user,
        [e.target.name]: e.target.value,
      },
    });
  };

  const updateCpf = (e) => {
    setRegister({
      ...register,
      [e.target.name]: cpfMask(e.target.value),
    });
  };

  const updatePhone = (e) => {
    setRegister({
      ...register,
      [e.target.name]: phoneMask(e.target.value),
    });
  };

  const updateCep = (e) => {
    setRegister({
      ...register,
      address: {
        ...register.address,
        [e.target.name]: cepMask(e.target.value),
      },
    });
  };
  const updateAddress = (e) => {
    setRegister({
      ...register,
      address: {
        ...register.address,
        [e.target.name]: e.target.value,
      },
    });
  };

  const updateSocial = (e) => {
    setRegister({
      ...register,
      user: {
        ...register.user,
        [e.target.name]: e.target.value,
      },
    });
  };

  const registerUser = (e) => {
    e.preventDefault();
    if (props.id) {
      if (
        register.user.email === "" ||
        register.user.first_name === "" ||
        register.user.last_name === "" ||
        register.user.gender === ""
      ) {
        container.error("Preencha os campos básicos obrigatórios ");
      } else if (
        register.phone !== "" &&
        register.phone &&
        register.phone.length < 14
      ) {
        container.error("Número de telefone inválido!");
      } else if (!emailRegex.test(register.user.email.toLowerCase())) {
        container.error("Email inválido!");
      } else if (register.user.password === "") {
        register.user.password = undefined;
      } else {
        if (register.user.password === "") {
          register.user.password = undefined;
        }
        Api.post("useredit", register)
          .then(() => {
            container.success("Usuário editado com sucesso!");
            close();
          })
          .catch(() => {
            Swal.fire({
              title: "Ops!",
              icon: "error",
              text: "Ocorreu um erro interno, por favor tente novamente",
              confirmButtonText: "Ok",
            });
          });
      }
    } else {
      if (
        register.user.email === "" ||
        register.user.password === "" ||
        register.user.first_name === "" ||
        register.user.last_name === "" ||
        register.user.gender === ""
      ) {
        container.error("Preencha os campos básicos obrigatórios ");
      } else if (register.user.password !== register.user.confirmpassword) {
        container.error("Os campos de senhas precisam ser iguais!");
      } else if (
        register.phone !== "" &&
        register.phone &&
        register.phone.length < 14
      ) {
        container.error("Número de telefone inválido!");
      } else if (!emailRegex.test(register.user.email.toLowerCase())) {
        container.error("Email inválido!");
      } else {
        const query = {
          email: register.user.email,
          password: register.user.password,
          confirmpassword: register.user.confirmpassword,
          first_name: register.user.first_name,
          last_name: register.user.last_name,
          document: register.document,
          gender: register.user.gender,
          telephone: register.phone,
          zip_code: register.address.zip_code,
          street: register.address.street,
          complement: register.address.complement,
          neighborhood: register.address.neighborhood,
          number: register.address.number,
          state: register.address.state,
          city: register.address.city,
          facebook: register.user.facebook,
          instagram: register.user.instagram,
          x_twitter: register.user.x_twitter,
        };

        Api.post("register", query)
          .then(() => {
            container.success("Usuário cadastrado com sucesso!");
            close();
          })
          .catch(() => {
            container.error(
              "Usuário não cadastrado, favor verificar o formulário"
            );
          });
      }
    }
  };

  const getCep = () => {
    axios
      .get(`https://viacep.com.br/ws/${register.address.zip_code}/json`)
      .then((res) => {
        setRegister({
          ...register,
          address: {
            ...register.address,
            street: res.data.logradouro,
            state: res.data.uf,
            city: res.data.localidade,
            neighborhood: res.data.bairro,
            complement: res.data.complemento,
          },
        });
      })
      .catch(() => {
        setRegister({
          ...register,
          street: "",
          state: "",
          city: "",
          neighborhood: "",
          complement: "",
        });
      });
  };

  const close = () => {
    setRegister({
      id: "",
      document: "",
      telephone: "",
      user_id: "",
      address_id: "",
      address: {
        id: "",
        zip_code: "",
        street: "",
        neighborhood: "",
        complement: "",
        state: "",
        city: "",
        number: "",
      },
      user: {
        id: "",
        email: "",
        first_name: "",
        last_name: "",
        password: "",
        confirmpassword: "",
        gender: "",
        facebook: "",
        instagram: "",
        x_twitter: "",
        qrcode: "",
      },
    });
    props.callback("modal-hidden");
  };

  const fetchData = useCallback(() => {
    if (props.id) {
      Api.get(`showUser/${props.id}`)
        .then((res) => {
          res.data.address[0]
            ? setRegister({
                address: {
                  id: res.data?.address[0].id,
                  zip_code: res.data?.address[0].zip_code,
                  street: res.data?.address[0].street,
                  neighborhood: res.data?.address[0].neighborhood,
                  complement: res.data?.address[0].complement,
                  state: res.data?.address[0].state,
                  city: res.data?.address[0].city,
                  number: res.data?.address[0].number,
                },
                document: res.data.user.document,
                telephone: res.data.user.telephone,
                user: {
                  id: res.data.user.id,
                  email: res.data.user.email ? res.data.user.email : "",
                  first_name: res.data.user.first_name
                    ? res.data.user.first_name
                    : "",
                  last_name: res.data.user.last_name
                    ? res.data.user.last_name
                    : "",
                  gender: res.data.user.gender ? res.data.user.gender : "",
                  qrcode: res.data.user.qrcode ? res.data.user.qrcode : "",
                  facebook: res.data.user.facebook || "",
                  instagram: res.data.user.instagram || "",
                  x_twitter: res.data.user.x_twitter || "",
                },
              })
            : setRegister({
                address: {
                  id: "",
                  zip_code: "",
                  street: "",
                  neighborhood: "",
                  complement: "",
                  state: "",
                  city: "",
                  number: "",
                },
                document: res.data.user.document,
                telephone: res.data.user.telephone,
                user: {
                  id: res.data.user.id,
                  email: res.data.user.email ? res.data.user.email : "",
                  first_name: res.data.user.first_name
                    ? res.data.user.first_name
                    : "",
                  last_name: res.data.user.last_name
                    ? res.data.user.last_name
                    : "",
                  gender: res.data.user.gender ? res.data.user.gender : "",
                  qrcode: res.data.user.qrcode ? res.data.user.qrcode : "",
                  facebook: res.data.user.facebook || "",
                  instagram: res.data.user.instagram || "",
                  x_twitter: res.data.user.x_twitter || "",
                },
              });
        })
        .catch(() => {
          Swal.fire({
            title: "Ops!",
            icon: "error",
            text: "Ocorreu um erro interno, por favor tente novamente",
            confirmButtonText: "Ok",
          });
        });
    }
  }, [props.id]);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.id]);

  const gerarQRCODE = () => {
    Api.get(`/gera/qrcode/user/${props.id}`)
      .then(() => {
        fetchData();
      })
      .catch(() => {
        Swal.fire({
          title: "Ops!",
          icon: "error",
          text: "Ocorreu um erro interno, por favor tente novamente",
          confirmButtonText: "Ok",
        });
      });
  };

  return (
    <>
      <div
        id="venda-detalhe"
        className={"shadow side-modal " + props.status}
        role="dialog"
      >
        <div>
          <div className="card-header">
            <h4 className="card-header-title">
              {props.id ? "Editar usuário" : "Cadastrar usuário"}
            </h4>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={(e) => close(e)}
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="card-body">
            <h4 className="card-header-title mb-2">1. Campos obrigatórios</h4>
            <ToastContainer
              ref={(ref) => (container = ref)}
              className="toast-top-right"
            />
            <div className="row mt-2">
              <div className={props.id ? "col-md-8" : "col-md-6"}>
                <label htmlFor="email">E-mail</label>
                <form method="post" autoComplete="off">
                  <input
                    type="email"
                    placeholder="exemplo@seuemail.com.br"
                    className="form-control"
                    name="email"
                    value={register.user.email}
                    onChange={updateUser}
                  />
                </form>
                {props.id ? (
                  <>
                    <label htmlFor="first_name">Nome</label>
                    <input
                      type="text"
                      placeholder="Nome"
                      className="form-control"
                      name="first_name"
                      value={register.user.first_name}
                      onChange={updateUser}
                    />

                    <label htmlFor="last_name">Sobrenome</label>
                    <input
                      type="text"
                      placeholder="Sobrenome"
                      className="form-control"
                      name="last_name"
                      value={register.user.last_name}
                      onChange={updateUser}
                    />

                    <label htmlFor="gender">Gênero</label>
                    <select
                      className="form-control"
                      name="gender"
                      value={register.user.gender}
                      onChange={updateUser}
                    >
                      {genderOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                      </select>
                    <label htmlFor="last_name">Alterar Senha</label>
                    <input
                      type="password"
                      placeholder="Nova Senha"
                      className="form-control"
                      name="password"
                      minlength="6"
                      value={register.user.password}
                      onChange={updateUser}
                    />
                  </>
                ) : (
                  ""
                )}
              </div>
              <div className={props.id ? "col-md-4" : "col-md-3"}>
                {props.id === null ? (
                  <>
                    <label htmlFor="password">Senha</label>
                    <form method="post" autoComplete="off">
                      <input
                        type="password"
                        placeholder="Senha"
                        className="form-control"
                        name="password"
                        id="password"
                        value={register.user.password}
                        onChange={updateUser}
                      />
                    </form>
                  </>
                ) : register.user.qrcode ? (
                  <div style={{ textAlign: "center" }}>
                    <label>QRcode</label>
                    <br />
                    <QRCode value={register.user.qrcode} />
                  </div>
                ) : (
                  <>
                    <label>QRcode</label>
                    <button
                      className="btn btn-primary c3"
                      onClick={gerarQRCODE}
                    >
                      Gerar QRcode
                    </button>
                  </>
                )}
              </div>
              {props.id === null ? (
                <>
                  <div className="col-md-3">
                    <label htmlFor="confirmpassword">Confirme a Senha</label>
                    <form method="post" autoComplete="off">
                      <input
                        type="password"
                        placeholder="Confirme a senha"
                        className="form-control"
                        name="confirmpassword"
                        value={register.confirmpassword}
                        onChange={updateUser}
                      />
                    </form>
                  </div>
                </>
              ) : (
                ""
              )}
            </div>

            {!props.id ? (
              <>
                <div className="row mt-2">
                  <div className="col-md-6">
                    <label htmlFor="first_name">Nome</label>
                    <input
                      type="text"
                      placeholder="Nome"
                      className="form-control"
                      name="first_name"
                      value={register.user.first_name}
                      onChange={updateUser}
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="last_name">Sobrenome</label>
                    <input
                      type="text"
                      placeholder="Sobrenome"
                      className="form-control"
                      name="last_name"
                      value={register.user.last_name}
                      onChange={updateUser}
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="gender">Gênero</label>
                    <select
                      className="form-control"
                      name="gender"
                      value={register.user.gender}
                      onChange={updateUser}
                    >
                      {genderOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
          </div>
        </div>
        <div>
          <div className="card-header">
            <h4 className="card-header-title">2. Dados Opcionais</h4>
          </div>
          <div className="card-body">
            <div id="dados-pessoais">
              <div className="row mt-2">
                <div className="col-md-4">
                  <label htmlFor="cpf">CPF ou CNPJ</label>
                  <input
                    type="text"
                    placeholder="000.000.000-00"
                    className="form-control"
                    name="document"
                    value={register.document || ""}
                    onChange={updateCpf}
                  />
                </div>
                <div className="col-md-4">
                  <label htmlFor="phone">Telefone</label>
                  <input
                    type="text"
                    placeholder="Telefone"
                    className="form-control"
                    name="telephone"
                    value={register.telephone || ""}
                    onChange={updatePhone}
                  />
                </div>
                <div className="col-md-4">
                  <label htmlFor="zip_code">CEP</label>
                  <input
                    type="text"
                    placeholder="Cep"
                    className="form-control"
                    name="zip_code"
                    value={register.address.zip_code || ""}
                    onChange={updateCep}
                    onBlur={getCep}
                  />
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-8">
                  <label htmlFor="street">Rua</label>
                  <input
                    type="text"
                    placeholder="Rua"
                    className="form-control"
                    name="street"
                    value={register.address.street || ""}
                    onChange={updateAddress}
                  />
                </div>
                <div className="col-md-4">
                  <label htmlFor="number">Número</label>
                  <input
                    type="text"
                    placeholder="Número"
                    className="form-control"
                    name="number"
                    value={register.address.number || ""}
                    onChange={updateAddress}
                  />
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-8">
                  <label htmlFor="neighborhood">Bairro</label>
                  <input
                    type="text"
                    placeholder="Bairro"
                    className="form-control"
                    name="neighborhood"
                    value={register.address.neighborhood || ""}
                    onChange={updateAddress}
                  />
                </div>
                <div className="col-md-4">
                  <label htmlFor="complement">Complemento</label>
                  <input
                    type="text"
                    placeholder="Complemento"
                    className="form-control"
                    name="complement"
                    value={register.address.complement || ""}
                    onChange={updateAddress}
                  />
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-8">
                  <label htmlFor="city">Cidade</label>
                  <input
                    type="text"
                    placeholder="Cidade"
                    className="form-control"
                    name="city"
                    value={register.address.city || ""}
                    onChange={updateAddress}
                  />
                </div>
                <div className="col-md-4">
                  <label htmlFor="state">Estado</label>
                  <input
                    type="text"
                    placeholder="Estado"
                    className="form-control"
                    name="state"
                    value={register.address.state || ""}
                    onChange={updateAddress}
                  />
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-4">
                  <label htmlFor="facebook">Facebook</label>
                  <input
                    type="text"
                    placeholder="Facebook"
                    className="form-control"
                    name="facebook"
                    value={register.user.facebook || ""}
                    onChange={updateSocial}
                  />
                </div>
                <div className="col-md-4">
                  <label htmlFor="instagram">Instagram</label>
                  <input
                    type="text"
                    placeholder="Instagram"
                    className="form-control"
                    name="instagram"
                    value={register.user.instagram || ""}
                    onChange={updateSocial}
                  />
                </div>
                <div className="col-md-4">
                  <label htmlFor="x_twitter">X (Twitter)</label>
                  <input
                    type="text"
                    placeholder="X (Twitter)"
                    className="form-control"
                    name="x_twitter"
                    value={register.user.x_twitter || ""}
                    onChange={updateSocial}
                  />
                </div>
              </div>
            </div>

            <div>
              <div className="box-button pt-3">
                <button
                  className="btn btn-primary col-md-4 c3"
                  onClick={registerUser}
                >
                  {props.id ? "Salvar" : "Cadastrar"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalUsers;
